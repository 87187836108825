import MJSONDesigner from '@admin/components/mjson_designer'
import ImageTools from '@admin/components/image_tools'
import ApplyTemplate from './apply_template'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    advisor: PropTypes.object,
    landing_page: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { advisor, landing_page } = this.props
    return {
      endpoint: `/forms_landing_pages/${landing_page.id}`,
      library: `/advisors/${advisor.id}`,
      preview: `/mjson/dynamic/forms_landing_pages/${landing_page.code}`,
      root_route: `/truevail/admin/advisors/${advisor.id}/landing_page-${landing_page.id}`,
      type: 'page',
      versionable: true
    }
  }

}

const getApplyTemplate = (props) => ({
  advisor: props.advisor,
  endpoint: `/api/admin/truevail/admin/advisors/${props.advisor.id}/landing_pages/${props.landing_page.id}/apply`,
  type: 'landing_page'
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design advisor={ props.advisor } landing_page={ props.landing_page } />,
  tasks: {
    items: [
      {
        label: 't(Apply Template)',
        modal: {
          options: { width: 1168 },
          component: <ApplyTemplate { ...getApplyTemplate(props) } />
        }
      },
      { 
        label: 't(Generate Image)',
        modal: <ImageTools palette={props.theme?.value?.theme?.palette } /> 
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  ...props.theme ? {
    theme: `/api/admin/maha_themes/${props.theme.id}/config/versions/published`
  } : {}
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
