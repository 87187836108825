import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Clone extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    return {
      title: 't(Clone Social Campaign)',
      action: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}/clone`,
      method: 'POST',
      saveText: 't(Clone)',
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', placeholder: 't(Enter the title)', required: true, defaultValue: `Copy of ${social_campaign.title}` },
            { label: 't(Design URL)', name: 'design_url', type: 'urlfield', defaultValue: social_campaign.design_url },
            { label: 't(Tags)', name: 'tags', type: 'tagfield', endpoint: '/api/admin/tags', defaultValue: social_campaign.tags.map(tag => tag.text) }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess(social_campaign) {
    this.context.router.push(`/truevail/admin/social_campaigns/${social_campaign.id}`)
    this.context.modal.close()
  }

}

export default Clone
