import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    template: PropTypes.object,
    template_variant: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { template, template_variant } = this.props
    return {
      title: 't(Edit Variant)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/templates/${template.id}/variants/${template_variant.id}/edit`,
      action: `/api/admin/truevail/admin/templates/${template.id}/variants/${template_variant.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Email Details)', type: 'segment', fields: [
              { label: 't(Subject)', name: 'subject', type: 'textfield', required: true },
              { label: 't(Preview Text)', name: 'preview_text', type: 'textfield' }
            ] }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Edit
