import Panel from '@admin/components/panel'

const mapPropsToPanel = (props, context) => ({
  title: 't(Domain Segments)',
  collection: {
    endpoint: '/api/admin/truevail/admin/domain_segments',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Domain)', key: 'domain.name', sort: 'domain', primary: true },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment' },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' },
      { label: 't(Rate)', key: 'sending_rate', collapsing: true, align: 'right' }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Segment)', key: 'segment', type: 'select', multiple: true, endpoint: '/api/admin/truevail/admin/segments', valueKey: 'id', textKey: 'text' }
          ]
        }
      ],
      system: [
        { id: 0, title: 't(TLDs)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'tld_id', operator: '$nl', parent: null, value: true }
          ]
        } },
        { id: 1, title: 't(Subdomains)', config: {
          criteria: [
            { code: 'abc', data: null, field: 'tld_id', operator: '$nnl', parent: null, value: true }
          ]
        } }
      ]
    },
    defaultSort: { key: 'domain', order: 'asc' },
    empty: {
      icon: 'globe',
      title: 't(No Domain Segments)',
      text: 't(There are no domain segments)'
    },
    entity: 'domain segment',
    onClick: (record) => context.router.push(`/admin/truevail/admin/domain_segments/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)