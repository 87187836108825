import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    agency: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { team } = this.context.admin
    const { agency } = this.props
    return {
      title: 't(Edit Agency)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/edit`,
      action: `/api/admin/truevail/admin/agencies/${agency.id}`,
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { label: 't(Title)', name: 'title', type: 'textfield', required: true },
            { label: 't(Logo)', name: 'logo_id', type: 'attachmentfield', allow: { types: ['photos'] }, multiple: false },
            { 
              label: 't(Voice)', 
              type: 'segment', 
              fields: [
                { 
                  label: 'Perspective', 
                  type: 'dropdown', 
                  name: 'perspective', 
                  options: [
                    { value: 'first-person singular', text: 't(First Person Singular)' },
                    { value: 'first-person plural', text: 't(First Person Plural)' }
                  ], 
                  defaultValue: 'first-person plural', 
                  required: true 
                },
                { 
                  label: 'Language', 
                  type: 'dropdown', 
                  name: 'language', 
                  options: [
                    { value: 'american english', text: 't(English) (t(American))' },
                    { value: 'canadian english', text: 't(English) (t(Canadian))' },
                    { value: 'french', text: 't(French)' },
                    { value: 'german', text: 't(German)' },
                    { value: 'italian', text: 't(Italian)' }
                  ], 
                  defaultValue: 'american english', 
                  required: true 
                }
              ], 
              required: true 
            },
            { 
              label: 't(Voices)', 
              type: 'segment', 
              fields: [
                { 
                  label: 't(Perspectives)', 
                  type: 'checkboxgroup', 
                  name: 'perspectives', options: [
                    { value: 'first-person singular', text: 't(First Person Singular)' },
                    { value: 'first-person plural', text: 't(First Person Plural)' }
                  ], 
                  required: true 
                },
                { 
                  label: 't(Languages)', 
                  type: 'checkboxgroup', 
                  name: 'languages', options: [
                    { value: 'american english', text: 't(English) (t(American))' },
                    { value: 'canadian english', text: 't(English) (t(Canadian))' }
                  ], 
                  required: true 
                }
              ],
              required: true
            },
            { label: 't(Public Domain)', name: 'public_domain', type: 'domainfield', provider_id: 2, required: true },
            { label: 't(Advisor Domains)', name: 'domain_ids', type: 'checkboxgroup', endpoint: '/api/admin/domains', provider_id: team.provider.id, valueKey: 'id', textKey: 'name', multiple: true, required: true }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default New
