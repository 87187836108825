import SocialBatchToken from '@apps/truevail/admin/tokens/social_batch'
import MJSONScreenshot from '@admin/components/mjson_screenshot'
import EntityChooser from '@admin/components/entity_chooser'
import List from '@admin/components/list'
import PropTypes from 'prop-types'
import React from 'react'

class Import extends React.PureComponent {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSave = this._handleSave.bind(this)
  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    return {
      endpoint: '/api/admin/truevail/library/social_batches',
      entity: 'social batch',
      multiple: true,
      format: (social_batch) => (
        <SocialBatchToken social_batch={ social_batch } />
      ),
      preview: (social_batch) => (
        <List { ...this._getList(social_batch) } />
      ),
      showHeader: true,
      onCancel: this._handleCancel,
      onDone: this._handleSave
    }
  }

  _getList(social_batch) {
    return {
      items: social_batch.social_campaigns,
      format: (social_campaign) => (
        <div className="campaign-token">
          <div className="campaign-token-preview">
            <div className="campaign-token-preview-inner">
              <MJSONScreenshot { ...this._getScreenshot(social_campaign) } />
            </div>
          </div>
          <div className="campaign-token-label">
            <div className="token-link">
              { social_campaign.title }
            </div>
            <div className="token-stats">
              { social_campaign.type }            
            </div>
          </div>
        </div>
      ),
      scrollable: true
    }
  }

  _getScreenshot(social_campaign) {
    return {
      engine: 'mjson',
      entity: `truevail_social_campaign_variants/${social_campaign.variants[0].code}`,
      width: 100,
      height: 75,
      screenshoted_at: social_campaign.screenshoted_at,
      updated_at: social_campaign.updated_at
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleDone() {
    this.context.modal.close()
  }

  _handleSave(social_batches) {
    this.context.network.request({
      endpoint: '/api/admin/truevail/agency/social_batches',
      method: 'POST',
      body: {
        social_batch_ids: social_batches.map(social_batch => social_batch.id)
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to import social batches)'),
      onSuccess: this._handleDone
    })
  }

}

export default Import
