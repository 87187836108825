import SubscriptionProfileToken from '@apps/truevail/admin/tokens/subscription_profile'
import TimeFrameField from '@apps/truevail/admin/components/timeframefield'
import SchedulingToken from '@apps/truevail/admin/tokens/scheduling'
import ChannelToken from '@apps/truevail/admin/tokens/channel'
import Container from '@admin/components/container'
import ProfileToken from '@admin/tokens/profile'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Edit extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object,
    profiles: PropTypes.array,
    subscription: PropTypes.object
  }

  state = {
    config: {}
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { advisor, profiles, subscription } = this.props
    const { config } = this.state
    return {
      title: 't(Edit Subscription)',
      method: 'PATCH',
      endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/subscriptions/${subscription.id}/edit`,
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/subscriptions/${subscription.id}`,
      onCancel: this._handleCancel,
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...subscription.type === 'email' ? [
              { label: 't(Sender)', name: 'sender_id', type: 'dropdown', entity: 'sender', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/senders`, valueKey: 'id', textKey: 'rfc822' },
              { label: 't(List)', name: 'list_id', type: 'dropdown', entity: 'list', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/lists`, valueKey: 'id', textKey: 'title', required: true },
              { label: 't(Email Template)', name: 'email_template_id', type: 'dropdown', entity: 'template', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/templates`, valueKey: 'id', textKey: 'title' },
              { label: 't(Scheduling)', name: 'scheduling', type: 'radiogroup', options: ['optout','optin'], format: SchedulingToken, defaultValue: 'optout', required: true },
              { label: 't(Channels)', name: 'channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/channels`, valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'email' } }, format: (channel) => (
                <ChannelToken channel={ channel } />
              ) }
            ] : subscription.type === 'social' ? [
              { label: 't(Profiles)', name: 'subscription_profiles', type: 'collectionfield', fields: [
                { label: 't(Profile)', name: 'profile_id', type: 'dropdown', options: profiles, valueKey: 'id', textKey: 'username', format: (profile) => (
                  <ProfileToken profile={ profile } />
                ), required: true },
                { label: 't(Hashtags)', name: 'hashtags', type: 'textfield' }
              ], entity: 'profile', orderable: false, cloneable: false, token: ({ value }) => (
                <SubscriptionProfileToken { ...this._getSubscriptionProfile(value) } />
              ) },
              { label: 't(Scheduling)', type: 'segment', fields: [
                { name: 'scheduling', type: 'radiogroup', options: ['optout','optin'], format: SchedulingToken, defaultValue: 'optout', required: true },
                { name: 'scheduling_timeframe', type: TimeFrameField, show: config.scheduling === 'optout', required: true }
              ], required: true },
              { label: 't(Before Caption)', name: 'config.before_caption', type: 'textarea' },
              { label: 't(After Caption)', name: 'config.after_caption', type: 'textarea' },
              { label: 't(Channels)', name: 'channel_ids', type: 'checkboxgroup', entity: 'channel', endpoint: `/api/admin/truevail/admin/advisors/${advisor.id}/channels`, valueKey: 'id', textKey: 'title', filter: { service: { $eq: 'social' } }, format: (channel) => (
                <ChannelToken channel={ channel } />
              ) }
            ] : subscription.type === 'legacy_website' ? [
              { 
                name: 'type', 
                type: 'hidden', 
                value: 'legacy_website' 
              },
              { 
                label: 't(URL)',
                name: 'url',
                type: 'urlfield',
                required: true
              },
              { 
                label: 't(Login URL)',
                name: 'login_url',
                type: 'urlfield'
              } 
            ] : []
          ]
        }
      ]
    }
  }

  _getSubscriptionProfile(value) {
    const { profiles } = this.props
    const profile = profiles.find(profile => {
      return profile.id === value.profile_id
    })
    return {
      profile,
      hashtags: value.hashtags
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  profiles: `/api/admin/truevail/admin/advisors/${props.advisor.id}/profiles`
})

export default Container(mapResources)(Edit)