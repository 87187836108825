import Panel from '@admin/components/panel'
import Import from './import'
import React from 'react'
import Edit from './edit'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 't(Library Users)',
  collection: {
    endpoint: '/api/admin/truevail/agency/library_users',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'rfc822', primary: true },
      { label: 't(Last Active)', key: 'last_active_at', collapsing: true, format: 'datetime' },
      { label: 't(Enabled)', key: 'is_active', collapsing: true, format: 'check_times' }
    ],
    defaultSort: { key: 'last_name', order: 'asc' },
    empty: {
      icon: 'user',
      title: 't(No Users)',
      text: 't(You have not yet created any library users)',
      buttons: [
        {
          label: 't(Create Library User)',
          modal: New
        },
        { 
          label: 't(Import Library Users)',
          modal: Import
        }
      ]
    },
    recordTasks: (record) => [
      {
        label: 't(Edit Library User)',
        modal: <Edit library_user={ record } />
      },
      {
        label: 't(Enable Library User)',
        show: !record.is_active,
        request: {
          endpoint: `/api/admin/truevail/agency/library_users/${record.id}/enable`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully enabled library user)'),
          onFailure: () => context.flash.set('error', 't(Unable to enable library user)')
        }
      },
      {
        label: 't(Disable Library User)',
        show: record.is_active,
        request: {
          endpoint: `/api/admin/truevail/agency/library_users/${record.id}/disable`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully disabled library user)'),
          onFailure: () => context.flash.set('error', 't(Unable to disable library user)')
        }
      },
      {
        label: 't(Delete Library User)',
        confirm: 't(Are you sure you want to delete this library user?)',
        request: {
          endpoint: `/api/admin/truevail/agency/library_users/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted library user)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete library user)')
        }
      }
    ],
    entity: 'user'
  },
  tasks: {
    icon: 'plus',
    items: [
      {
        label: 't(Add Library User)',
        modal: New
      }, {
        label: 't(Import Library Users)',
        modal: Import
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
