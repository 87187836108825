import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_email_campaign_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at,
  placeholder: entity.status === 'pending'
})

const EmailCampaignToken = ({ email_campaign, compact }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(email_campaign.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { email_campaign.title }
      </div>
    </div>
  </div>
)

EmailCampaignToken.propTypes = {
  email_campaign: PropTypes.object,
  compact: PropTypes.bool
}

export default EmailCampaignToken
