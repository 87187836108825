import SocialCampaign from './social_campaign'
import Panel from '@admin/components/panel'
import React from 'react'

const MetricToken = ({ campaign, metric }) => (
  <div className="social-metric-token">
    <div className="social-metric-token-total">
      { campaign[`total_${metric}`] }
    </div>
    <div className="social-metric-token-subtotals">
      <img src="/images/services/instagram_business.png" /> { campaign[`instagram_${metric}`] } <img src="/images/services/facebook.png" />  { campaign[`facebook_${metric}`] }
    </div>
  </div>
)

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/social_performances/${props.social_campaign.id}/campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Publishing)', key: 'advisor.title', sort: 'advisor', format: (social_campaign) => (
        <SocialCampaign social_campaign={ social_campaign } />
      ) },
      { label: 't(Impressions)', key: 'total_impressions', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="impressions" />
      ) },
      { label: 't(Engagement)', key: 'total_engagement', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="engagement" />
      ) },
      { label: 't(Reach)', key: 'total_reach', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="reach" />
      ) },
      { label: 't(Video Views)', key: 'total_video_views', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="video_views" />
      ) },
      { label: 't(Reactions)', key: 'total_reactions', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="reactions" />
      ) },
      { label: 't(Comments)', key: 'total_comments', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="comments" />
      ) },
      { label: 't(Shares)', key: 'total_shares', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="shares" />
      ) },
      { label: 't(Saves)', key: 'total_saves', collapsing: true, format: (campaign) => (
        <MetricToken campaign={ campaign } metric="saves" />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Social Campaigns)',
      text: 't(Your advisors have not yet sent any socials)'
    },
    entity: 'publishing',
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/mjson/dynamic/campaigns_social_campaigns/${record.code}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
