import EmailCampaignToken from '@apps/truevail/admin/tokens/email_campaign'
import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Choose extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { formdata } = this.props
    const strategy = this._getStrategy(formdata.strategy)
    return {
      endpoint: strategy.endpoint,
      entity: strategy.entity,
      format: strategy.format,
      multiple: false,
      screenshot: strategy.screenshot,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _getStrategy(strategy) {
    return strategy === 'email_campaign' ? {
      endpoint: '/api/admin/truevail/library/email_campaigns',
      entity: 'email campaign',
      format: (email_campaign) => (
        <EmailCampaignToken email_campaign={ email_campaign } show_provider={ true } />
      ),
      screenshot: (entity) => `truevail_email_campaign_variants/${entity.variants[0].code}`
    } : {
      endpoint: `/api/admin/truevail/library/templates/${strategy}`,
      entity: strategy.replace(/_/g, ' '),
      format: (template) => (
        <TemplateToken template={ template } show_provider={ true } />
      ),
      screenshot: (entity) => `truevail_template_variants/${entity.variants[0].code}`
    }
  }

  _handleDone(truevail_template) {
    this.props.onNext({ 
      truevail_template
    })
  }

}

export default Choose