import MJSONDesigner from '@admin/components/mjson_designer'
import ImageTools from '@admin/components/image_tools'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    template: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { template } = this.props
    return {
      endpoint: `/truevail_template_variants/${template.variants[0].id}`,
      root_route: `/truevail/admin/templates/${template.id}/design`,
      ...['automation_email','email_introduction','email_article','email_campaign','email_module','email_template'].includes(template.type) ? {
        type: 'email'
      } : {
        type: 'page'
      },
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design template={ props.template } />,
  tasks: {
    items: [
      { 
        label: 't(Generate Image)',
        modal: <ImageTools palette={props.theme?.value?.theme?.palette } /> 
      }
    ]
  }
})

const mapResourcesToPanel = (props, context) => ({
  ...props.theme ? {
    theme: `/api/admin/truevail_template_variants/${props.theme.variants[0].id}/config/versions/published`
  } : {}
})

export default Panel(mapResourcesToPanel, mapPropsToPanel)
