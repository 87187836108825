import PropTypes from 'prop-types'
import React from 'react'

const DurationToken = ({ duration }) => (
  <div className="token">
    { duration > 24 && 
      <>{ `${Math.floor(duration / 24)}D` }</>
    } { duration % 24 }H
  </div>
)

DurationToken.propTypes = {
  duration: PropTypes.number
}

export default DurationToken