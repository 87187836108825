import LibraryActivities from './library_activities'
import SocialPerformance from './social_performance'
import SocialPublishings from './social_publishings'
import EmailPerformance from './email_performance'
import EmailPublishings from './email_publishings'
import SocialCampaigns from './social_campaigns'
import EmailCampaigns from './email_campaigns'
import SocialBatches from './social_batches'
import SupportUsers from './support_users'
import LibraryUsers from './library_users'
import Page from '@admin/components/page'
import Templates from './templates'
import Channels from './channels'
import Advisors from './advisors'
import Overview from './overview'
import Folders from './folders'
import Users from './users'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: context.admin.team.title,
  manager: {
    path: '/admin/truevail/agency',
    items: [
      {
        label: 't(Overview)',
        panel: <Overview agency={ resources.agency } />
      },
      {
        label: 't(Advisors)',
        path: '/advisors',
        panel: <Advisors />
      },
      {
        label: 't(Content Library)',
        children: [
          {
            label: 't(Activities)',
            path: '/library_activities',
            panel: <LibraryActivities />
          },
          {
            label: 't(Email Articles)',
            path: '/email_articles',
            panel: <Templates type="email_article" />
          },
          {
            label: 't(Email Campaigns)',
            path: '/email_campaigns',
            panel: <EmailCampaigns />
          },
          {
            label: 't(Email Modules)',
            path: '/email_modules',
            panel: <Templates type="email_module" />
          },          
          {
            label: 't(Email Templates)',
            path: '/email_templates',
            panel: <Templates type="email_template" />
          },
          {
            label: 't(Folders)',
            path: '/folders',
            panel: <Folders />
          },
          {
            label: 't(Social Batches)',
            path: '/social_batches',
            panel: <SocialBatches />
          },
          {
            label: 't(Social Campaigns)',
            path: '/social_campaigns',
            panel: <SocialCampaigns />
          },
          {
            label: 't(Users)',
            path: '/library_users',
            panel: <LibraryUsers />
          }
        ]
      },
      {
        label: 't(Email)',
        children: [
          {
            label: 't(Channels)',
            path: '/email_channels',
            panel: <Channels service="email" />
          },
          {
            label: 't(Publishings)',
            path: '/email_publishings',
            panel: <EmailPublishings />
          },
          {
            label: 't(Performance)',
            path: '/email_performance',
            panel: <EmailPerformance />
          }
        ]
      },
      {
        label: 't(Social Media)',
        children: [
          {
            label: 't(Channels)',
            path: '/social_channels',
            panel: <Channels service="social" />
          },
          {
            label: 't(Publishings)',
            path: '/social_publishings',
            panel: <SocialPublishings />
          },
          {
            label: 't(Performance)',
            path: '/social_performance',
            panel: <SocialPerformance />
          }
        ]
      },
      // {
      //   label: 't(Websites)',
      //   children: [
      //     {
      //       label: 't(Blog Posts)',
      //       path: '/posts',
      //       panel: <Foo />
      //     },
      //     {
      //       label: 't(Modules)',
      //       path: '/modules',
      //       panel: <Modules medium="page" />
      //     },
      //     {
      //       label: 't(Templates)',
      //       path: '/email_templates',
      //       panel: <Foo />
      //     }
      //   ]
      // },
      {
        label: 't(Support Users)',
        path: '/support_users',
        panel: <SupportUsers />
      },
      {
        label: 't(Users)',
        path: '/users',
        panel: <Users />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  agency: '/api/admin/truevail/agency'
})

export default Page(mapResourcesToPage, mapPropsToPage)
