import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import SourceToken from '@admin/tokens/source'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign } = this.props
    const networks = this._getSources() 
    return {
      title: 't(Create Variants)',
      action: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}/variants`,
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Source)',
              name: 'source_email_campaign_variant_id', 
              type: 'dropdown', 
              entity: 'source variant',
              options: social_campaign.variants,
              valueKey: 'id',
              format: VariantVoiceToken,
              selectFirst: true,
              required: true
            },
            { 
              label: 't(Networks)', 
              name: 'sources', 
              type: 'checkboxgroup', 
              entity: 'network', 
              options: networks, 
              valueKey: 'source_name', 
              textKey: 'name', 
              format: (source) => (
                <SourceToken source={ source } />
              ),
              defaultValue: social_campaign.sources, 
              required: true
            },
            { 
              label: 't(Voices)',
              name: 'voices',
              type: 'checkboxgroup',
              options: ['all','first-person plural','first-person singular'].reduce((perspectives, perspective) => [
                ...perspectives,
                ...['all','american english','canadian english','french','german','italian'].reduce((languages, language) => [
                  ...languages,
                  {
                    value: { perspective, language },
                    perspective,
                    language
                  }
                ], [])
              ], []),
              deselectable: true,
              groupKey: 'perspective',
              valueKey: 'value',
              textKey: 'service',
              format: VariantVoiceToken,
              groupFormat: ({ value }) => (
                <VariantVoiceToken perspective={ value} />
              ),
              required: true
            }
          ]
        }
      ]
    }
  }

  _getSources() {
    const { social_campaign } = this.props
    return [
      ..._.includes(['photo','video','link','story','reel'], social_campaign.type) ? [
        { name: 'Facebook', source_name: 'facebook' }
      ] : [],
      ..._.includes(['photo','video','story','reel'], social_campaign.type) ? [
        { name: 'Instagram', source_name: 'instagram_business' }
      ] : [],
      // ..._.includes(['photo','video','link'], formdata.type) ? [
      //   { name: 'LinkedIn', source_name: 'linkedin' }
      // ] : []
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Manage
