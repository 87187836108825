import PurposeToken from '@apps/campaigns/admin/tokens/purpose'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'
import def from 'ajv/dist/vocabularies/discriminator'

class Details extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleBack = this._handleBack.bind(this)
  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { formdata } = this.props
    const { config } = this.state
    const { event_id, field_id, form_id, list_id, program_id, service_id, store_id, tag_id, webinar_id, trigger } = formdata
    const pipeline_id = config.pipeline_id || formdata.pipeline_id
    const email_campaign_id = this._getEmailCampaignId()
    const email_id = this._getAutomationEmailId()
    const filter = { program_id: { $eq: program_id } }
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, handler: this._handleBack },
        { label: 't(Save)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            ...trigger === 'automation:enrollment_created' ? [
              { 
                label: 't(Title)', 
                name: 'title', 
                type: 'textfield',
                required: true 
              },
              { 
                label: 't(Purpose)', 
                name: 'purpose', 
                type: 'radiogroup', 
                options: ['marketing','transactional'], 
                required: true, 
                format: PurposeToken, 
                defaultValue: 'marketing' 
              }
            ] : [],
            ..._.includes(['automation:email_received','automation:email_opened','automation:email_clicked'], trigger) && !formdata.email_id ? [
              { 
                label: 't(Automation Email)', 
                name: 'email_id', 
                type: 'lookup', 
                endpoint: '/api/admin/automation/emails', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['automation:email_clicked'], trigger) && email_id ? [
              { 
                label: 't(Link)', 
                name: 'email_link_id', 
                type: 'lookup', 
                endpoint: `/api/admin/automation/emails/${email_id}/links`, 
                valueKey: 'id', 
                textKey: 'url', 
                required: true 
              }
            ] : [],
            ..._.includes(['campaigns:email_received','campaigns:email_opened','campaigns:email_clicked'], trigger) && !formdata.email_campaign_id ? [
              { 
                label: 't(Email Campaign)', 
                name: 'email_campaign_id', 
                type: 'lookup', 
                endpoint: '/api/admin/campaigns/email', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['campaigns:email_clicked'], trigger) && email_campaign_id ? [
              { 
                label: 't(Link)', 
                name: 'email_link_id', 
                type: 'lookup', 
                endpoint: `/api/admin/campaigns/email/${email_campaign_id}/links`, 
                valueKey: 'id', 
                textKey: 'url', 
                required: true 
              }
            ] : [],
            ...trigger === 'crm:property_updated' && !field_id ? [
              { 
                label: 't(Property)', 
                name: 'field_id', 
                type: 'lookup', 
                endpoint: `/api/admin/crm_programs/${program_id}/fields`, 
                valueKey: 'id', 
                textKey: 'name.value', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['crm:subscription_created','crm:subscription_deleted'], trigger) && !list_id ? [
              { 
                label: 't(List)', 
                name: 'list_id', 
                type: 'lookup', 
                endpoint: '/api/admin/crm/lists', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['crm:tag_added','crm:tag_removed'], trigger) && !tag_id ? [
              { 
                label: 't(Tag)', 
                name: 'tag_id', 
                type: 'lookup', 
                endpoint: '/api/admin/tags', 
                valueKey: 'id', 
                textKey: 'text', 
                filter, 
                required: true 
              }
            ] : [],
            ...!trigger === 'events:registration_created' && event_id ? [
              { 
                label: 't(Event)', 
                name: 'event_id', 
                type: 'lookup', 
                endpoint: '/api/admin/events/events', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ...trigger === 'forms:response_created' && !form_id ? [
              { 
                label: 't(Form)', 
                name: 'form_id', 
                type: 'lookup', 
                endpoint: '/api/admin/forms/forms', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ...trigger === 'maha:team_status_duration' ? [
              { 
                label: 't(Truevail Entity Type)', 
                name: 'truevail_entity_type', 
                type: 'dropdown',
                options: [
                  { value: null, text: 't(Any)' },
                  { value: 'agency', text: 't(Agency)' },
                  { value: 'advisor', text: 't(Advisor)' },
                  { value: 'supplier', text: 't(Supplier)' }
                ],
                required: true,
                defaultValue: null
              },
              { 
                label: 't(Status)', 
                name: 'team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled'],
                required: true 
              },
              { 
                label: 't(Duration)',
                name: 'duration', 
                type: 'durationfield',
                required: true
              }
            ] : [],
            ...trigger === 'maha:team_status_transitioned' ? [
              { 
                label: 't(Truevail Entity Type)', 
                name: 'truevail_entity_type', 
                type: 'dropdown',
                options: [
                  { value: null, text: 't(Any)' },
                  { value: 'agency', text: 't(Agency)' },
                  { value: 'advisor', text: 't(Advisor)' },
                  { value: 'supplier', text: 't(Supplier)' }
                ],
                required: true,
                defaultValue: null
              },
              { 
                label: 't(From Status)', 
                name: 'from_team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled']
              },
              { 
                label: 't(To Status)', 
                name: 'to_team_status', 
                type: 'dropdown',
                options: ['pending','configured','unconfigured','active','canceled']
              }
            ] : [],
            ..._.includes(['sales:deal_created','sales:deal_updated'], trigger) && !formdata.pipeline_id ? [
              { 
                label: 't(Pipeline)', 
                name: 'pipeline_id', 
                type: 'dropdown', 
                endpoint: '/api/admin/sales/pipelines', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ...trigger === 'sales:deal_transitioned' ? [
              ...!pipeline_id ? [
                { 
                  label: 't(Pipeline)', 
                  name: 'pipeline_id', 
                  type: 'dropdown', 
                  endpoint: '/api/admin/sales/pipelines', 
                  valueKey: 'id', 
                  textKey: 'title', 
                  filter, 
                  required: true 
                }  
              ] : [],
              { 
                label: 't(From Stage)', 
                name: 'from_stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${pipeline_id}/stages`, 
                valueKey: 'id', 
                textKey: 'title',
                show: pipeline_id !== undefined
              },
              { 
                label: 't(To Stage)', 
                name: 'to_stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${pipeline_id}/stages`, 
                valueKey: 'id', 
                textKey: 'title',
                show: pipeline_id !== undefined
              }
            ] : [],
            ...trigger === 'sales:deal_stage_duration' ? [
              ...!pipeline_id ? [
                { 
                  label: 't(Pipeline)', 
                  name: 'pipeline_id', 
                  type: 'dropdown', 
                  endpoint: '/api/admin/sales/pipelines', 
                  valueKey: 'id', 
                  textKey: 'title', 
                  filter, 
                  required: true 
                }  
              ] : [],
              { 
                label: 't(Stage)', 
                name: 'stage_id', 
                type: 'dropdown', 
                endpoint: `/api/admin/sales/pipelines/${pipeline_id}/stages`, 
                valueKey: 'id', 
                textKey: 'title',
                show: pipeline_id !== undefined
              },
              { 
                label: 't(Duration)',
                name: 'duration', 
                type: 'durationfield',
                required: true
              }
            ] : [],
            ..._.includes(['stores:order_created','stores:order_shipped'], trigger) && !store_id ? [
              { 
                label: 't(Store)',
                name: 'store_id', 
                type: 'lookup', 
                endpoint: '/api/admin/stores/stores', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['subscriptions:subscription_created','subscriptions:renewal_success','subscriptions:renewal_failure','subscriptions:payment_method_expiring','subscriptions:payment_method_expired'], trigger) && !service_id ? [
              { 
                label: 't(Service)', 
                name: 'service_id', 
                type: 'lookup', 
                endpoint: '/api/admin/subscriptions/services', 
                valueKey: 'id', 
                textKey: 'title', 
                filter, 
                required: true 
              }
            ] : [],
            ..._.includes(['truevail:participant_registered','truevail:participant_attended','truevail:participant_noshowed','truevail:participant_converted'], trigger) && !webinar_id ? [
              { 
                label: 't(Webinar)',
                name: 'webinar_id', 
                type: 'lookup', 
                endpoint: '/api/admin/truevail/admin/webinars', 
                valueKey: 'id',
                textKey: 'title'
              }
            ] : [],
            { 
              label: 't(Enrollment)', 
              name: 'is_unique', 
              type: 'radiogroup', 
              options: [
                { value: false, text: 't(Contacts can be enrolled in this workflow multiple times)' },
                { value: true, text: 't(Contacts can only be enrolled in this workflow once)' }
              ], 
              defaultValue: false 
            }
          ]
        }
      ]
    }
  }

  _getEmailCampaignId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_campaign_id || config.email_campaign_id || null
  }

  _getAutomationEmailId() {
    const { formdata } = this.props
    const { config } = this.state
    return formdata.email_id || config.email_id || null
  }

  _handleBack() {
    this.props.onBack()
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(workflow) {
    this.props.onSave(workflow)
  }

}

export default Details
