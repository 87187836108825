import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import Assign from '@apps/truevail/admin/components/assign'
import Panel from '@admin/components/panel'
import React from 'react'

const getAssign = (social_batch) => ({
  action: `/api/admin/truevail/admin/social_batches/${social_batch.id}/social_campaigns`,
  assigned_endpoint: `/api/admin/truevail/admin/social_batches/${social_batch.id}/social_campaigns`,
  unassigned_endpoint: `/api/admin/truevail/admin/social_batches/${social_batch.id}/social_campaigns/unassigned`
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/admin/social_batches/${props.social_batch.id}/social_campaigns`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Social Campaign)', key: 'title', primary: true, format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } />
      ) },
      { label: 't(Variants)', key: 'variants_count', collapsing: true, align: 'right' }
    ],
    sortable: false,
    defaultSort: { key: 'id', order: 'asc' },
    empty: {
      icon: 'comments',
      title: 't(No Social Campaigns)',
      text: 't(You have not yet added any social campaigns to this batch)',
      buttons: [
        { label: 't(Assign Campaigns)', modal: <Assign { ...getAssign(props.social_batch) } /> }
      ]
    },
    entity: 'social campaigns'
  },
  task: {
    icon: 'plus',
    modal: <Assign { ...getAssign(props.social_batch) } />
  }
})

export default Panel(null, mapPropsToPanel)
