import StrategyToken from './strategy_token'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Type extends React.PureComponent {

  static contextTypes = {
    form: PropTypes.object
  }

  static propTypes = {
    formdata: PropTypes.object,
    profiles: PropTypes.array,
    props: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }

  _handleChange = this._handleChange.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    return {
      ref: this.formRef,
      showHeader: false,
      buttons: [
        { label: 't(Previous)', color: 'blue', basic: true, disabled: true },
        { label: 't(Next)', color: 'blue', handler: this._handleSubmit }
      ],
      onChange: this._handleChange,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              name: 'strategy', 
              type: 'radiogroup', 
              options: ['scratch','social_campaign'], 
              format: StrategyToken, 
              defaultValue: 'scratch', 
              required: true 
            }
          ]
        }
      ]
    }
  }

  _handleChange(config) {
    this.setState({ config })
  }

  _handleSubmit() {
    this.formRef.current.submit()
  }

  _handleSuccess(email_campaign) {
    this.props.onNext(email_campaign)
  }

}

export default Type
