import T from '@admin/components/t'
import PropTypes from 'prop-types'
import React from 'react'

const strategies = {
  social_campaign: {
    title: 't(Import Truevail Campaign)',
    text: 't(Choose from one of Truevail\'s many social campaigns)'
  },
  scratch: {
    title: 't(Start From Scratch)',
    text: 't(Start with an empty social campaign)'
  }
}

const StrategyToken = ({ value }) => (
  <div className="token">
    <strong><T text={ strategies[value].title } /></strong><br />
    <T text={ strategies[value].text } />
  </div>
)

StrategyToken.propTypes = {
  value: PropTypes.string
}

export default StrategyToken
