import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    email_campaign: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { email_campaign } = this.props
    return {
      title: 't(Create Variants)',
      action: `/api/admin/truevail/admin/email_campaigns/${email_campaign.id}/variants`,
      method: 'POST',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Source)',
              name: 'source_email_campaign_variant_id', 
              type: 'dropdown', 
              entity: 'source variant',
              options: email_campaign.variants,
              valueKey: 'id',
              format: VariantVoiceToken,
              selectFirst: true,
              required: true
            },
            { 
              label: 't(Voices)',
              name: 'voices',
              type: 'checkboxgroup',
              options: ['all','first-person plural','first-person singular'].reduce((perspectives, perspective) => [
                ...perspectives,
                ...['all','american english','canadian english','french','german','italian'].reduce((languages, language) => [
                  ...languages,
                  {
                    value: { perspective, language },
                    perspective,
                    language
                  }
                ], [])
              ], []),
              deselectable: true,
              groupKey: 'perspective',
              valueKey: 'value',
              textKey: 'service',
              format: VariantVoiceToken,
              groupFormat: ({ value }) => (
                <VariantVoiceToken perspective={ value} />
              ),
              required: true
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Manage
