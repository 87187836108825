import BounceTypeToken from '@apps/automation/admin/tokens/bounce_type'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Bounces)',
  access: {},
  collection: {
    endpoint: '/api/admin/truevail/admin/deliverability/bounces',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: (email) => <ContactToken { ...email.contact } property="rfc822" /> },
      { label: 't(Bounce Type)', key: 'bounce_subtype', primary: true, padded: true, collapsing: true, format: BounceTypeToken }
    ],
    defaultQuery: {
      ...props.params.domain_id ? { 
        domain_id: props.params.domain_id
      } : {},
      ...props.params.segment_id ? { 
        segment_id: props.params.segment_id
      } : {},
      ...props.params.domain_segment_id ? { 
        domain_segment_id: props.params.domain_segment_id
      } : {},
      interval: props.params.interval,
      value: props.params.value
    },
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      icon: 'exclamation',
      title: 't(No Bounces)',
      text: 't(There were no bounces for this time period)'
    },
    entity: 'bounce',
    onClick: (record) => context.router.push(`/emails/${record.code}`)
  }
})

export default Panel(null, mapPropsToPanel)
