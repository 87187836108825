import Container from '@admin/components/container'
import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(New Subscription)',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/subscriptions`,
      method: 'POST',
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      props: {
        advisor
      },
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(subscription) {
    return {
      channel_ids: subscription.channel_ids,
      email_template_id: subscription.email_template_id,
      list_id: subscription.list_id,
      scheduling: subscription.scheduling,
      scheduling_timeframe: subscription.scheduling_timeframe,
      sender_id: subscription.sender_id,
      subscription_profiles: subscription.subscription_profiles,
      type: subscription.type,
      url: subscription.url,
      login_url: subscription.login_url,
      config: subscription.config
    }
  }

  _getSteps() {
    return [
      { label: 't(Type)', component: Type },
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(subscription) {
    const { advisor } = this.props
    this.context.router.push(`/truevail/admin/advisors/${advisor.id}/subscriptions/${subscription.id}`)
    this.context.modal.close()
  }

}

const mapResources = (props, context) => ({
  programs: {
    endpoint: '/api/admin/programs',
    filter: {
      access_type: {
        $in: ['manage','edit']
      }
    }
  }
})

export default Container(mapResources)(New)
