import SocialCampaignToken from '@apps/truevail/admin/tokens/social_campaign'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Campaign extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    const { social_campaigns } = this.state
    return {
      defaultValue: social_campaigns,
      endpoint: '/api/admin/truevail/library/social_campaigns',
      entity: 'social campaign',
      format: (social_campaign) => (
        <SocialCampaignToken social_campaign={ social_campaign } show_provider={ true }/>
      ),
      multiple: false,
      screenshot: (entity) => `truevail_social_campaign_variants/${entity.variants[0].code}`,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(social_campaign) {
    this.props.onNext({ social_campaign })
  }

}

export default Campaign
