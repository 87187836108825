import Format from '@admin/components/format'
import PropTypes from 'prop-types'
import React from 'react'

const getQuantity = (domain, metric) => {
  if(metric === 'open_rate') return domain.opened_count
  if(metric === 'click_rate') return domain.clicked_count
  if(metric === 'bounce_rate') return domain.bounced_count
  if(metric === 'hard_bounce_rate') return domain.hard_bounced_count
  if(metric === 'soft_bounce_rate') return domain.soft_bounced_count  
  if(metric === 'complain_rate') return domain.complained_count
  if(metric === 'unsubscribe_rate') return domain.unsubscribed_count
}

const getHighlight = (domain, metric) => {
  if(metric === 'soft_bounce_rate' && domain.soft_bounce_rate >= 0.05) return true
  if(metric === 'hard_bounce_rate' && domain.hard_bounce_rate >= 0.05) return true
  if(metric === 'complain_rate' && domain.complain_rate >= 0.001) return true
  return false
}

const DeliverabilityRate = ({ domain, metric }) => (
  <>
    { getQuantity(domain, metric) } (<span className={ getHighlight(domain, metric) ? 'alert' : 'light' }>
      <Format value={ domain[metric] } format="rate" />
    </span>)  
  </>
  
)

DeliverabilityRate.propTypes = {
  domain: PropTypes.object,
  metric: PropTypes.string
}

export default DeliverabilityRate