import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Schedule from '../schedule'
import Preview from '../preview'
import Publish from '../publish'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  collection: {
    endpoint: `/api/admin/truevail/agency/social_campaigns/${props.social_campaign.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      icon: 'comments',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this social campaign)',
      buttons: [
        {
          label: 't(Publish Social Campaigns)',
          modal: <Publish social_campaign={ props.social_campaign } />
        }
      ]
    },
    entity: 'publishing',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        modal: <Preview publishing={ record } />
      },
      {
        label: 't(Republish Social Campaign)',
        show: _.includes(['draft','scheduled','unpublished'], record.advisor_social_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/republish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully republished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to republish social campaign)')
        }
      },
      {
        label: 't(Schedule Social Campaign)',
        show: _.includes(['draft','unpublished'], record.advisor_social_campaign.status),
        modal: <Schedule social_campaign_id={ props.social_campaign.id } publishing={ record } />
      },
      {
        label: 't(Reschedule Social Campaign)',
        show: record.advisor_social_campaign.status === 'scheduled',
        modal: <Schedule social_campaign_id={ props.social_campaign.id } publishing={ record } />
      },
      {
        label: 't(Unpublish Social Campaign)',
        show: _.includes(['draft','scheduled'], record.advisor_social_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish social campaign)')
        }
      },
      {
        label: 't(Unschedule Social Campaign)',
        show: record.advisor_social_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unscheduled social campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unschedule social campaign)')
        }
      },
      // {
      //   label: 't(Delete Social Campaigns)',
      //   confirm: 't(Are you sure you want to delete these social campaigns?)',
      //   request: {
      //     endpoint: `/api/admin/truevail/agency/social_campaigns/${props.social_campaign.id}/publishings/${record.id}`,
      //     method: 'DELETE',
      //     onSuccess: () => context.flash.set('success', 't(Successfully deleted social campaigns)'),
      //     onFailure: () => context.flash.set('error', 't(Unable to delete social campaigns)')
      //   }
      // }
    ],
    onClick: (record) => {
      const { provider, host } = context
      const path = `campaigns/social/${record.advisor_social_campaign.id}`
      host.window.open(`${provider.admin_host}/${record.subscription.advisor.team.subdomain}/${path}`)
    }
  },
  task: {
    icon: 'plus',
    tooltip: 't(Publish Social Campaigns)',
    modal: <Publish social_campaign={ props.social_campaign } />
  }
})

export default Panel(null, mapPropsToPanel)
