import VoiceToken from '@apps/truevail/admin/tokens/voice'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import Transition from '../transition'
import PropTypes from 'prop-types'
import moment from 'moment'
import Edit from '../edit'
import React from 'react'

const Overview = ({ agency }) => {

  const primary_user = agency.team.primary_user ? {
    className: 'link',
    label: agency.team.primary_user.display_name,
    route: `/admin/crm/contacts/${agency.team.primary_user.id}`
  } : null

  const subscription = agency.team.subscription ? {
    className: 'link',
    label: agency.team.subscription.plan.title,
    route: `/admin/subscriptions/services/${agency.team.subscription.service_id}/subscriptions/${agency.team.subscription.id}`
  } : null

  const unschedule = {
    label: 't(Unschedule Transition)',
    className: 'link',
    confirm: 't(Are you sure you want to unschedule this transition?)',
    request: {
      endpoint: `/api/admin/truevail/admin/agencies/${agency.id}/transition`,
      method: 'PATCH',
      body: {
        action: 'unschedule'
      }
    }
  }

  const details = {
    ...agency.team.deliverability_status === 'suspended' ? {
      alert: { color: 'red', message: 't(This agency\'s ability to send email is suspended)' }
    } : agency.team.deliverability_status === 'suspended' ? {
      alert: { color: 'red', message: 't(This agency\'s ability to send email is suspended)' }
    } : agency.team.status === 'active' ? {
      alert: { color: 'green', message: 't(This agency is active)' }
    } : agency.team.status === 'canceled' ? {
      alert: { color: 'grey', message: 't(This agency is canceled)' }
    } : agency.team.status === 'pending' ? {
      alert: { color: 'teal', message: 't(This agency is pending)' }
    } : agency.team.status === 'pending' ? {
      alert: { color: 'red', message: 't(This agency is inactive)' }
    } : agency.team.status === 'paused' ? {
      alert: { color: 'yellow', message: 't(This agency is paused)' }
    } : agency.team.status === 'activating' ? {
      alert: { color: 'teal', message: (
        <>This agency will be activated on { moment(agency.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : agency.team.status === 'pausing' ? {
      alert: { color: 'teal', message: (
        <>This agency will be paused on { moment(agency.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : agency.team.status === 'resuming' ? {
      alert: { color: 'teal', message: (
        <>This agency will be resumed on { moment(agency.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : agency.team.status === 'reactivating' ? {
      alert: { color: 'teal', message: (
        <>This agency will be reactivated on { moment(agency.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : agency.team.status === 'canceling' ? {
      alert: { color: 'yellteatealloteal', message: (
        <>This agency will be canceled on { moment(agency.team.transition_at).format('MM/DD/YY [@] h:mm A') }. <Button { ...unschedule } /></>
      ) }
    } : {},
    audits: `truevail_agencies/${agency.id}`,
    comments: `truevail_agencies/${agency.id}`,
    sections: [
      {
        title: 't(Agency Details)',
        items: [
          { label: 't(Name)', content: agency.name },
          { label: 't(Organization)', content: agency.organization },
          { label: 't(Voice)', content: <VoiceToken perspective={ agency.perspective } language={ agency.language } /> },
          { label: 't(Perspectives)', content: agency.perspectives?.join(', ') || null },
          { label: 't(Languages)', content: agency.languages?.join(', ') || null },
          { label: 't(Public Domain)', content: agency.team.public_domain },
          { label: 't(Advisor Domains)', content: agency.domains.map((domain,index) => (
            <div key={`domain_${index}`}>{ domain.name }</div>
          )) },
          { label: 't(Timezone)', content: agency.team.timezone.zone },
          { label: 't(Primary User)', content: primary_user ? <Button { ...primary_user } /> : null },
          { label: 't(Billing Agency)', content: subscription ? <Button { ...subscription } /> : null }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  agency: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview agency={ props.agency } />,
  tasks: {
    items: [
      {
        label: 't(Edit Agency)',
        modal: <Edit agency={ props.agency } />
      },
      {
        label: 't(Activate Agency)',
        show: props.agency.team.status === 'pending',
        modal: <Transition agency={ props.agency } action="activate" />
      },
      {
        label: 't(Pause Agency)',
        show: props.agency.team.status === 'active',
        modal: <Transition agency={ props.agency } action="pause" />
      },
      {
        label: 't(Cancel Agency)',
        show: props.agency.team.status === 'active',
        modal: <Transition agency={ props.agency } action="cancel" />
      },
      {
        label: 't(Resume Agency)',
        show: props.agency.team.status === 'paused',
        modal: <Transition agency={ props.agency } action="resume" />
      },
      {
        label: 't(Reactivate Agency)',
        show: props.agency.team.status === 'canceled',
        modal: <Transition agency={ props.agency } action="reactivate" />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
