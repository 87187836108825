import ModalPanel from '@admin/components/modal_panel'
import Message from '@admin/components/message'
import Button from '@admin/components/button'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import React from 'react'

class Complete extends React.Component {

  static propTypes = {
    _import: PropTypes.object,
    onDone: PropTypes.func
  }

  _handleDone = this._handleDone.bind(this)

  render() {
    return (
      <ModalPanel { ...this._getPanel() }>
        <Message { ...this._getMessage() } />
      </ModalPanel>
    )
  }

  _getPanel() {
    return {
      title: 't(Importing Complete)'
    }
  }

  _getMessage() {
    const { rows_count } = this.props._import.config
    let estimate = rows_count * 0.5
    const units = estimate < 60 ? 'second' : 'minute'
    if(units === 'minute') estimate = Math.ceil(estimate / 60)
    const kb = {
      label: 't(Learn more about our automated verification process in our Knowledge Base)',
      className: 'link',
      link: 'https://help.truevail.com/article/292-bounce-rate-policy'
    }
    return {
      title: 't(Contact List Upload Complete)',
      text: (
        <>
          <p>Your contacts have been imported and are now going through our automated verification process. Until this process is complete and your contacts are verified, you will not be able to send a campaign to them.</p>
          <p>Your upload of { rows_count } contacts is estimated to take around { pluralize(units, estimate, true) } to be verified.</p>
          <p><Button { ...kb } /></p>. 
        </>
      ),
      icon: 'check',
      color: 'green',
      animation: 'tada',
      button: {
        label: 't(Finish)',
        handler: this._handleDone
      }
    }
  }

  _handleDone() {
    this.props.onDone()
  }

}

export default Complete
