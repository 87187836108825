import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import PropTypes from 'prop-types'
import React from 'react'

class Choose extends React.PureComponent {

  static propTypes = {
    formdata: PropTypes.object,
    onBack: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onNext: PropTypes.func,
    onSave: PropTypes.func
  }

  formRef = React.createRef()

  state = {
    config: {}
  }  

  _handleDone = this._handleDone.bind(this)

  render() {
    return <EntityChooser { ...this._getEntityChooser() } />
  }

  _getEntityChooser() {
    return {
      endpoint: '/api/admin/truevail/library/templates/landing_page',
      entity: 'landing page',
      format: (template) => (
        <TemplateToken template={ template } show_provider={ true } />
      ),
      screenshot: (entity) => `truevail_template_variants/${entity.variants[0].code}`,
      multiple: false,
      showHeader: false,
      onDone: this._handleDone
    }
  }

  _handleDone(truevail_template) {
    this.props.onNext({ 
      truevail_template
    })
  }

}

export default Choose
