import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Segments)',
  collection: {
    endpoint: `/api/admin/truevail/admin/domains/${props.domain.id}/domain_segments`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Segment)', key: 'segment.text', sort: 'segment' },
      { label: 't(Queued)', key: 'queued_count', collapsing: true, align: 'right' },
      { label: 't(Rate)', key: 'sending_rate', collapsing: true, align: 'right' }
    ],
    defaultSort: { key: 'segment', order: 'asc' },
    empty: {
      icon: 'sitemap',
      title: 't(No Domain Segments)',
      text: 't(There are no domain segments)'
    },
    entity: 'domain segment',
    recordTasks: (record) => [
      {
        label: 't(Promote Emails)',
        confirm: 't(Are you sure you want to bypass the queue and deliver these emails?)',
        request: {
          endpoint: `/api/admin/truevail/admin/domain_segments/${record.id}/promote`,
          method: 'PATCH'
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/domain_segments/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
