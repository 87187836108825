import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Choose from './choose'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    form: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { form } = this.props
    return {
      title: 't(New Embed)',
      method: 'POST',
      action: `/api/admin/forms/forms/${form.id}/embeds`,
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(embed) {
    return {
      title: embed.title,
      theme_id: embed.theme_id,
      strategy: embed.strategy,
      ...embed.truevail_template ? {
        truevail_template_id: embed.truevail_template.id
      } : {}
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Type)', component: Type },
      ...formdata.strategy !== 'scratch' ? [
        { label: 't(Choose)', component: Choose }
      ] : [],
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(embed) {
    const { form } = this.props
    this.context.router.push(`/admin/forms/forms/${form.id}/embeds/${embed.id}`)
    this.context.modal.close()
  }

}

export default New