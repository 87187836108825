import ProgramForm from '@apps/crm/admin/components/programform'
import SocialSchedule from '@admin/components/social_schedule'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const getSocialSchedule = () => ({
  defaultMode: 'month',
  endpoint: '/api/admin/campaigns/social',
  editable: true
})

const mapPropsToPanel = (props, context) => ({
  title: 't(Social Campaigns)',
  access: { rights: { $oneOf: ['campaigns:view_social_campaigns','campaigns:manage_social_campaigns'] } },
  component: <SocialSchedule { ...getSocialSchedule() } />,
  task: {
    icon: 'plus',
    tooltip: 't(Create Social Campaign)', 
    access: { 
      rights: { $oneOf: ['campaigns:manage_social_campaigns'] } 
    },
    modal: <ProgramForm form={ New } />        
  }
})

export default Panel(null, mapPropsToPanel)

