import Page from '@admin/components/page'
import Overview from './overview'
import Variants from './variants'
import Design from './design'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.template.title,
  manager: {
    path: `/admin/truevail/agency/templates/${resources.template.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview template={ resources.template } />
      },
      {
        label: 't(Variants)',
        path: '/variants',
        panel: <Variants template={ resources.template } />
      },
      {
        label: 't(Design)',
        path: '/design',
        panel: <Design template={ resources.template }  />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  template: `/api/admin/truevail/agency/templates/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
