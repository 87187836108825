import VoiceToken from '@apps/truevail/admin/tokens/voice'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Translate from './translate'
import New from './new'
import Edit from './edit'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Variants)',
  collection: {
    endpoint: `/api/admin/truevail/admin/templates/${props.template.id}/variants`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Variant)', key: 'perspective', sort: 'voice', primary: true, format: (variant) => (
        <div className="token">
          { props.template.type === 'email_introduction' ? <>
            <strong><VoiceToken { ...variant } /></strong>
            <span>
              { variant.subject }<br />
              { variant.preview_text }
            </span>          
          </> : <VoiceToken { ...variant } />
          }
        </div>
      ) },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken }
    ],
    defaultSort: { key: 'voice', order: 'asc' },
    empty: {
      icon: 'comments',
      title: 't(No Variants)',
      text: 't(You have not yet created any variants of this template)'
    },
    entity: 'variant',
    recordTasks: (record) => [
      {
        label: 't(Edit Variant)',
        show: props.template.type === 'email_introduction',
        modal: <Edit template={ props.template } template_variant={ record } />
      },
      {
        label: 't(Translate Variant)',
        modal: <Translate template={ props.template } template_variant={ record } />
      },
      {
        label: 't(Delete Variant)',
        confirm: 't(Are you sure you want to delete this variant?)',
        request: {
          endpoint: `/api/admin/truevail/admin/templates/${props.template.id}/variants/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted variant)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete variant)')
        }
      }
    ]
  },
  task: {
    icon: 'plus',
    tooltip: 't(Create Variants)', 
    modal: <New template={ props.template } />
  }
})

export default Panel(null, mapPropsToPanel)
