import AestheticProfileToken from '@apps/truevail/admin/tokens/aesthetic_profile'
import LibraryToken from '@apps/truevail/admin/tokens/library'
import Panel from '@admin/components/panel'
import React from 'react'
import New from './new'

const mapPropsToPanel = (props, context) => ({
  title: 'Aesthetic Profiles',
  collection: {
    endpoint: '/api/admin/truevail/admin/aesthetic_profiles',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Title)', key: 'title', primary: true, format: (aesthetic_profile) => (
        <AestheticProfileToken aesthetic_profile={ aesthetic_profile } />
      ) },
      { label: 't(Library)', key: 'status', collapsing: true, format: LibraryToken }
    ],
    defaultSort: { key: 'created_at', order: 'desc' },
    empty: {
      title: 't(No Aesthetic Profiles)',
      text: 't(There are no branding packages)',
      icon: 'paint-brush',
      buttons: [
        {
          label: 't(Create New Aesthetic Profile)',
          modal: New
        }
      ]
    },
    entity: 'branding package',
    recordTasks: (record) => [
      {
        label: 't(Add to Library)',
        show: record.status === 'draft',
        request: {
          endpoint: `/api/admin/truevail/admin/aesthetic_profiles/${record.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully added this branding package to the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to add this branding package to the library)')
        }
      },
      {
        label: 't(Remove from Library)',
        show: record.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/admin/aesthetic_profiles/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this branding package from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this branding package from the library)')
        }
      }
    ],
    onClick: (record) => context.router.push(`/admin/truevail/admin/aesthetic_profiles/${record.id}`)
  },
  task: {
    icon: 'plus',
    modal: New
  }
})

export default Panel(null, mapPropsToPanel)
