import DomainSegments from './domain_segments'
import Page from '@admin/components/page'
import Subdomains from './subdomains'
import Segments from './segments'
import Overview from './overview'
import Domains from './domains'
import Minutes from './minutes'
import Bounces from './bounces'
import Emails from './emails'
import Hours from './hours'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  ...props.params.domain_id ? {
    title: `${props.domain.name} - ${props.params.value}`
  } : props.params.domain_segment_id ? {
    title: `${props.domain_segment.domain.name} - ${props.domain_segment.segment.text} - ${props.params.value}`
  } : props.params.segment_id ? {
    title: `${props.segment.text} - ${props.params.value}`
  } : {
    title: props.params.value
  },
  manager: {
    ...props.params.domain_id ? {
      path: `/admin/truevail/admin/sent/domains/${props.params.domain_id}/${props.params.interval}/${props.params.value}`
    } : props.params.domain_segment_id ? {
      path: `/admin/truevail/admin/sent/domain_segments/${props.params.domain_segment_id}/${props.params.interval}/${props.params.value}`
    } : props.params.segment_id ? {
      path: `/admin/truevail/admin/sent/segments/${props.params.segment_id}/${props.params.interval}/${props.params.value}`
    } : {
      path: `/admin/truevail/admin/sent/${props.params.interval}/${props.params.value}`
    },
    items: [
      {
        label: 't(Overview)',
        panel: <Overview { ...props } />
      },
      ...props.params.interval === 'day' ? [
        {
          label: 't(Hours)',
          panel: <Hours params={ props.params } />
        }
      ] : [
        {
          label: 't(Minutes)',
          panel: <Minutes  params={ props.params } />
        }
      ],
      ...!props.params.domain_id && !props.params.domain_segment_id ? [
        {
          label: 't(Domains)',
          path: '/domains',
          panel: <Domains params={ props.params } />
        }
      ] : [],
      ...!props.params.domain_id && !props.params.segment_id && !props.params.domain_segment_id ? [
        {
          label: 't(Domain Segments)',
          path: '/domain_segments',
          panel: <DomainSegments params={ props.params } />
        }
      ] : [],
      ...!props.params.segment_id && !props.params.domain_segment_id ? [
        {
          label: 't(Segments)',
          path: '/segments',
          panel: <Segments params={ props.params } />
        }
      ] : [],
      ...props.domain && !props.domain.tld_id ? [
        {
          label: 't(Subdomains)',
          path: '/subdomains',
          panel: <Subdomains params={ props.params } />
        }
      ] : [],
      {
        label: 't(Bounces)',
        path: '/bounces',
        panel: <Bounces params={ props.params } />
      },
      {
        label: 't(Emails)',
        path: '/emails',
        panel: <Emails params={ props.params } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  ...props.params.domain_id ? {
    domain: `/api/admin/truevail/admin/domains/${props.params.domain_id}`
  } : props.params.domain_segment_id ? {
    domain_segment: `/api/admin/truevail/admin/domain_segments/${props.params.domain_segment_id}`
  } : props.params.segment_id ? {
    segment: `/api/admin/truevail/admin/segments/${props.params.segment_id}`    
  } : {}
})

export default Page(mapResourcesToPage, mapPropsToPage)
