import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Convert extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    participant: PropTypes.object,
    webinar: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { participant, webinar} = this.props
    return {
      title: 't(Convert Demo)',
      action: `/api/admin/truevail/admin/webinars/${webinar.id}/participants/${participant.id}/convert`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Signup)', 
              name: 'signup_id', 
              type: 'lookup', 
              endpoint: '/api/admin/truevail/admin/signups', 
              valueKey: 'signup.id', 
              textKey: 'name',
              required: true 
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(agency) {
    this.context.modal.close()
  }

}

export default Convert
