import VariantVoiceToken from '@apps/truevail/admin/tokens/variant_voice'
import Form from '@admin/components/form'
import PropTypes from 'prop-types'
import React from 'react'

class Manage extends React.Component {

  static contextTypes = {
    modal: PropTypes.object
  }

  static propTypes = {
    social_campaign: PropTypes.object,
    social_campaign_variant: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <Form { ...this._getForm() } />
  }

  _getForm() {
    const { social_campaign, social_campaign_variant } = this.props
    return {
      title: 't(Translate Variant)',
      action: `/api/admin/truevail/admin/social_campaigns/${social_campaign.id}/variants/${social_campaign_variant.id}/translate`,
      method: 'PATCH',
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess,
      sections: [
        {
          fields: [
            { 
              label: 't(Source)', 
              name: 'source_social_campaign_variant_id', 
              type: 'dropdown', 
              entity: 'source variant',
              options: social_campaign.variants.filter(variant => {
                return variant.id !== social_campaign_variant.id && variant.service === social_campaign_variant.service
              }),
              valueKey: 'id',
              format: VariantVoiceToken,
              selectFirst: true,
              required: true
            }
          ]
        }
      ]
    }
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess(result) {
    this.context.modal.close()
  }

}

export default Manage
