import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Convert from '../convert'
import React from 'react'

const Overview = ({ participant, webinar }) => {

  const contact = {
    label: participant.contact.display_name,
    className: 'link',
    route: `/admin/crm/contacts/${participant.contact.id}`
  }

  const details = {
    audits: `truevail_participants/${participant.id}`,
    comments: `truevail_participants/${participant.id}`,
    sections: [
      {
        title: 't(Participant Details)',
        items: [
          { label: 't(Contact)', content: <Button { ...contact } /> }
        ]
      }
    ]
  }
  
  return <Details { ...details } />

}

Overview.propTypes = {
  participant: PropTypes.object,
  webinar: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview webinar={ props.webinar } participant={ props.participant } />,
  tasks: {
    items: [
      {
        label: 't(Mark as Converted)',
        show: props.participant.signup === null,
        modal: <Convert webinar={ props.webinar } participant={ props.participant } />
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
