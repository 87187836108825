import ProgramForm from '@apps/crm/admin/components/programform'
import ContactToken from '@apps/crm/admin/tokens/contact'
import Page from '@admin/components/page'
import Consent from './batch/consent'
import Import from '../imports/new'
import Lists from './batch/lists'
import criteria from './criteria'
import Tags from './batch/tags'
import React from 'react'
import New from './new'

const mapPropsToPage = (props, context, resources, page) => ({
  title: 't(Contacts)',
  access: { rights: { $oneOf: ['crm:view_contacts','crm:manage_contacts'] } },
  collection: {
    endpoint: '/api/admin/crm/contacts',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Name)', key: 'display_name', sort: 'last_name', primary: true, format: ContactToken },
      { label: 't(Email)', key: 'email', sort: 'primary_email' },
      { label: 't(Phone)', key: 'phone', sort: 'primary_phone', format: 'phone' },
      { label: 't(Organization)', key: 'organization', visible: false },
      { label: 't(Job Title)', key: 'job_title', visible: false },
      { label: 't(Birthday)', key: 'birthday', visible: false, format: 'date' },
      { label: 't(Spouse)', key: 'spouse', visible: false },
      { label: 't(Created)', key: 'created_at', visible: false, format: 'date' },
      { label: 't(Updated)', key: 'updated_at', visible: false, format: 'date' }
    ],
    criteria: {
      fields: criteria(resources.fields, context),
      system: [
        { id: 0, title: 't(Unknown Contacts)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'first_name', operator: '$nl', parent: 'abc', value: null },
            { code: 'ghi', data: null, field: 'last_name', operator: '$nl', parent: 'abc', value: null }
          ]
        } },
        { id: 1, title: 't(Potential Duplicates)', config: {
          criteria: [
            { code: 'abc', data: null, field: null, operator: '$and', parent: null, value: null },
            { code: 'def', data: null, field: 'duplicate_id', operator: '$nnl', parent: 'abc', value: null }
          ]
        } }
      ]
    },
    defaultSort: { key: 'last_name', order: 'asc' },
    export: [
      { label: 't(ID)', key: 'id' },
      { label: 't(First Name)', key: 'first_name' },
      { label: 't(Last Name)', key: 'last_name' },
      { label: 't(Email)', key: 'email' },
      { label: 't(Phone)', key: 'phone' },
      { label: 't(Address - Full Address)', key: 'address.description' },
      { label: 't(Address - Street 1)', key: 'address.street_1' },
      { label: 't(Address - Street 2)', key: 'address.street_2' },
      { label: 't(Address - City)', key: 'address.city' },
      { label: 't(Address - State/Province)', key: 'address.state_province' },
      { label: 't(Address - Postal Code)', key: 'address.postal_code' },
      { label: 't(Address - Latitude)', key: 'address.latitude' },
      { label: 't(Address - Longitude)', key: 'address.longitude' },
      { label: 't(Organization)', key: 'organization' },
      { label: 't(Job Title)', key: 'position' },
      { label: 't(Birthday)', key: 'birthday' },
      { label: 't(Spouse)', key: 'spouse' },
      { label: 't(Prefered Language)', key: 'language' },
      { label: 't(Website)', key: 'website' },
      { label: 'LinkedIn', key: 'linkedin' },
      { label: 'Twitter', key: 'twitter' },
      { label: 'Instagram', key: 'instagram' },
      { label: 'Facebook', key: 'facebook' },
      { label: 'YouTube', key: 'youtube' },
      ...resources.fields.reduce((fields, program) => [
        ...fields,
        ...program.fields.map(field => ({
          label: `${program.title} - ${field.name.value}`,
          key: `values.${field.code}`
        }))
      ], [])
    ],
    empty: {
      icon: 'user-circle',
      title: 't(No Contacts)',
      text: 't(You have not yet created any contacts)',
      buttons: [
        { 
          label: 't(Create Contact)',
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <New /> 
        },
        { 
          label: 't(Import Contacts)',
          access: { rights: { $oneOf: ['crm:manage_contacts'] } },
          modal: <ProgramForm form={ Import } /> 
        }
      ]
    },
    entity: 'contact',
    onClick: (record) => context.router.push(`/crm/contacts/${record.id}`),
    selectable: true,
    batch: {
      batch_type: 'contacts',
      tasks: [
        { label: 't(Update Consent)', action: 'consent', component: <ProgramForm form={ Consent } /> },
        { label: 't(Update Lists)', action: 'lists', component: <ProgramForm form={ Lists } /> },
        { label: 't(Update Tags)', action: 'tags', component: <ProgramForm form={ Tags } /> },
        { label: 't(Delete All)', action: 'delete', confirm: 't(Are you sure you want to delete these contacts?)'}
      ]
    }
  },
  tasks: {
    icon: 'plus',
    items: [
      {
        label: 't(Add Contact)',
        access: { rights: { $oneOf: ['crm:manage_contacts'] } },
        modal: <New />
      }, 
      {
        label: 't(Import Contacts)',
        access: { rights: { $oneOf: ['crm:manage_contacts'] } },
        modal: <ProgramForm form={ Import } />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  fields: '/api/admin/programs/fields'
})

export default Page(mapResourcesToPage, mapPropsToPage)
