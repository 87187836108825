import MultiForm from '@admin/components/multiform'
import PropTypes from 'prop-types'
import Choose from './choose'
import Details from './details'
import Type from './type'
import React from 'react'

class New extends React.Component {

  static contextTypes = {
    modal: PropTypes.object,
    router: PropTypes.object
  }

  static propTypes = {
    advisor: PropTypes.object
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleSuccess = this._handleSuccess.bind(this)

  render() {
    return <MultiForm { ...this._getMultiForm() } />
  }

  _getMultiForm() {
    const { advisor } = this.props
    return {
      title: 't(New Email Campaign)',
      action: `/api/admin/truevail/admin/advisors/${advisor.id}/email_campaigns`,
      method: 'POST',
      props: { advisor },
      formatData: this._getData.bind(this),
      getSteps: this._getSteps.bind(this),
      onCancel: this._handleCancel,
      onSuccess: this._handleSuccess
    }
  }

  _getData(email_campaign) {
    return {
      strategy: email_campaign.strategy,
      ...email_campaign.strategy !== 'scratch' ? {
        truevail_template_id: email_campaign.truevail_template.id
      } : {},
      title: email_campaign.title,
      purpose: email_campaign.purpose,
      subject: email_campaign.subject,
      preview_text: email_campaign.preview_text,
      template_id: email_campaign.template_id,
      sender_id: email_campaign.sender_id
    }
  }

  _getSteps(formdata) {
    return [
      { label: 't(Type)', component: Type },
      ...formdata.strategy !== 'scratch' ? [
        { label: 't(Choose)', component: Choose }
      ] : [],
      { label: 't(Details)', component: Details }
    ]
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleSuccess() {
    this.context.modal.close()
  }

}

export default New