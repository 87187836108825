import Details from '@admin/components/details'
import Button from '@admin/components/button'
import AssetToken from '@admin/tokens/asset'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'
import Edit from '../edit'

const getButton = (link) => link ? {
  label: link,
  className: 'link',
  link
} : null

const Overview = ({ aesthetic_profile }) => {

  const design_url = getButton(aesthetic_profile.design_url)
  const single_page = getButton(aesthetic_profile.single_page_url)
  const seven_page = getButton(aesthetic_profile.seven_page_url)
  const email_campaign = getButton(aesthetic_profile.email_campaign_url)
  const landing_page = getButton(aesthetic_profile.landing_page_url)

  const details = {
    audits: `truevail_aesthetic_profiles/${aesthetic_profile.id}`,
    comments: `truevail_aesthetic_profiles/${aesthetic_profile.id}`,
    sections: [
      {
        title: 't(Aesthetic Profile Details)',
        items: [
          { label: 't(Title)', content: aesthetic_profile.title },
          { label: 't(Description)', content: aesthetic_profile.description },
          { label: 't(Thumbnail)', content: aesthetic_profile.thumbnail ? (
            <AssetToken { ...aesthetic_profile.thumbnail } preview={ true } />
          ) : null },
          { label: 't(Poster)', content: aesthetic_profile.poster ? (
            <AssetToken { ...aesthetic_profile.poster } preview={ true } />
          ) : null },
          { label: 't(Video)', content: aesthetic_profile.video ? (
            <AssetToken { ...aesthetic_profile.video } preview={ true } />
          ) : null },
          { label: 't(Design URL)', content: design_url ? <Button { ...design_url } /> : null },
          { label: 't(Single Page)', content: single_page ? <Button { ...single_page } /> : null },
          { label: 't(Seven Page)', content: seven_page ? <Button { ...seven_page } /> : null },
          { label: 't(Email Campaign)', content: email_campaign ? <Button { ...email_campaign } /> : null },
          { label: 't(Landing Page)', content: landing_page ? <Button { ...landing_page } /> : null }
        ]
      }
    ]
  }

  if(aesthetic_profile.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This branding package was deleted)' }
  } else if(aesthetic_profile.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This branding package is not available in the library)' }
  } else if(aesthetic_profile.status === 'published') {
    details.alert = { color: 'green', message: 't(This branding package is available in the library)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  aesthetic_profile: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview aesthetic_profile={ props.aesthetic_profile } />,
  tasks: {
    items: [
      {
        label: 't(Edit Aesthetic Profile)',
        modal: <Edit aesthetic_profile={ props.aesthetic_profile } />
      },

      {
        label: 't(Add to Library)',
        show: props.aesthetic_profile.status === 'draft',
        request: {
          endpoint: `/api/admin/truevail/admin/aesthetic_profiles/${props.aesthetic_profile.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully added this branding package to the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to add this branding package to the library)')
        }
      },
      {
        label: 't(Remove from Library)',
        show: props.aesthetic_profile.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/admin/aesthetic_profiles/${props.aesthetic_profile.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this branding package from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this branding package from the library)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
