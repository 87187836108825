import ContactToken from '@apps/crm/admin/tokens/contact'
import StatusToken from '@admin/tokens/status'
import Format from '@admin/components/format'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Demos)',
  collection: {
    endpoint: '/api/admin/truevail/admin/demos',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Contact)', key: 'contact.display_name', sort: 'last_name', primary: true, format: ({ contact }) => {
        return <ContactToken { ...contact } />
      } },
      { label: 't(Start)', key: 'started_at', collapsing: true, format: 'datetime' },
      { label: 't(Status)', key: 'status', collapsing: true, primary: true, format: StatusToken },
      { label: 't(Customer)', key: 'is_customer', collapsing: true, align: 'center', format: 'check' },
      { label: 't(Converted)', key: 'signup.created_at', sort: 'converted_at', collapsing: true, align: 'center', format: (demo) => (
        <Format value={ demo.signup !== null } format='check' />
      ) }
    ],
    defaultSort: { key: 'started_at', order: 'desc' },
    empty: {
      title: 't(No Demos)',
      text: 't(There are no scheduled demos)',
      icon: 'laptop'
    },
    entity: 'demo',
    onClick: (record) => context.router.push(`/admin/truevail/admin/demos/${record.id}`)
  }
})

export default Panel(null, mapPropsToPanel)
