import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Profile Collection Failures)',
  collection: {
    endpoint: '/api/admin/truevail/admin/postability/profile_collection_failures',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'username', sort: 'profile', primary: true, format: (profile) => (
        <ProfileToken profile={ profile } />
      ) },
      { label: 't(Error)', key: 'error' },
      { label: 't(Collected)', key: 'last_collected_at', format: 'date' }
    ],
    defaultSort: { key: 'last_collected_at', order: 'desc' },
    empty: {
      icon: 'times',
      title: 't(No Collection Failures)',
      text: 't(There are no collection failures)'
    },
    entity: 'records',
    onClick: (record) => {
      const { provider, host } = context
      const { subdomain } = record.team
      host.window.open(`${provider.admin_host}/${subdomain}/team/profiles/${record.id}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
