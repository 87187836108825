import { CSSTransition } from 'react-transition-group'
import * as mjson from '@core/lib/mjson/document'
import SendPreview from './panels/email/preview'
import Navigator from './navigator'
import PropTypes from 'prop-types'
import entities from './entities'
import Header from './header'
import panels from './panels'
import Main from './main'
import React from 'react'

export const MJSONDesignerContext = React.createContext()
MJSONDesignerContext.displayName = 'MJSONDesignerContext'

export const useMJSONDesignerContext = () => React.useContext(MJSONDesignerContext)

export const devices = [
  { value: 'desktop', label: 'Desktop' },
  { value: 'tablet', label: 'Tablet', height: 1024 , width: 768 },
  { value: 'mobile', label: 'Mobile', height: 667, width: 375 }
]

class MJSONDesigner extends React.PureComponent {

  static childContextTypes = {
    designer: PropTypes.object
  }

  static contextTypes = {
    alert: PropTypes.object,
    confirm: PropTypes.object,
    form: PropTypes.object,
    host: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object,
    router: PropTypes.object,
    versions: PropTypes.object
  }

  static propTypes = {
    editable: PropTypes.bool,
    endpoint: PropTypes.string,
    language: PropTypes.string,
    library: PropTypes.string,
    perspective: PropTypes.string,
    preview: PropTypes.string,
    root_endpoint: PropTypes.string,
    type: PropTypes.string,
    versionable: PropTypes.bool,
    versions: PropTypes.array,
    version: PropTypes.object,
    website: PropTypes.object,
    onNavigate: PropTypes.func
  }

  state = {
    cards: [],
    darkMode: false,
    device: 'desktop',
    canvas_hover: null,
    images: true,
    outline_hover: null,
    orientation: 'portrait',
    settings: null
  }
  
  _handleAction = this._handleAction.bind(this)
  _handleAdd = this._handleAdd.bind(this)
  _handleClone = this._handleClone.bind(this)
  _handleCommit = this._handleCommit.bind(this)
  _handleDark = this._handleDark.bind(this)
  _handleDevice = this._handleDevice.bind(this)
  _handleEdit = this._handleEdit.bind(this)
  _handleImages = this._handleImages.bind(this)
  _handleInsert = this._handleInsert.bind(this)
  _handleLibrary = this._handleLibrary.bind(this)
  _handleMove = this._handleMove.bind(this)
  _handleNavigate = this._handleNavigate.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handleOrientation = this._handleOrientation.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handlePreview = this._handlePreview.bind(this)
  _handlePublish = this._handlePublish.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleRedo = this._handleRedo.bind(this)
  _handleRemove = this._handleRemove.bind(this)
  _handleSettings = this._handleSettings.bind(this)
  _handleUndo = this._handleUndo.bind(this)
  _handleUnlink = this._handleUnlink.bind(this)

  render() {
    const { version } = this.props
    const { settings } = this.state
    const Panel = this._getComponent()
    return (
      <MJSONDesignerContext.Provider value={ this.getChildContext() }>
        <div className={ this._getClass() }>
          <Header { ...this._getHeader() } />
          <div className="mjson-designer-body">
            <CSSTransition in={ settings !== null } appear={true} classNames="slide-in-right" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
              <div className="mjson-designer-settings">
                { Panel &&
                  <Panel { ...this._getPanel() } />
                }
              </div>
            </CSSTransition>
            { (true && version) &&
              <Main { ...this._getMain() } />
            }
            <CSSTransition in={ settings !== null } classNames="fade-in-half" timeout={ 250 } mountOnEnter={ true } unmountOnExit={ true }>
              <div className="mjson-designer-overlay" onClick={ this._handleSettings.bind(this, null) } />
            </CSSTransition>
          </div>
        </div>
      </MJSONDesignerContext.Provider>
    )
  }

  componentDidMount() {
    this._handlePush(Navigator, this._getNavigator.bind(this))
  }

  getChildContext() {
    return {
      designer: {
        device: this._handleDevice
      }
    }
  }

  _getClass() {
    const { settings } = this.state
    const classes = ['mjson-designer']
    if(settings) classes.push('settings')
    return classes.join(' ')
  }

  _getComponent() {
    const { settings } = this.state
    const { type } = this.props
    return panels[type][settings]
  }

  _getConfig() {
    return this.props.version.current
  }

  _getEdit(id) {
    const { type, website } = this.props
    const config = this._getConfig()
    const path = mjson.getPath(config, id)
    const pathParts = path?.split('.') || []
    const parent = pathParts?.length >= 3 ? pathParts.slice(0,-2).join('.') : null
    const { device } = this.state
    return {
      key: path || 'page',
      device,
      entity: path ? _.get(config, path) : config,
      parent: parent ? _.get(config, parent) : null,
      type,
      theme: config.theme,
      website,
      onChange: this._handleUpdate.bind(this, false, false, id),
      onDone: this._handleUpdate.bind(this, true, true, id)
    }
  }

  _getEntity(action, entity) {
    const { type } = this.props
    return entities[type][action][entity]
  }

  _getHeader() {
    const { editable, preview, type, version, versionable, website } = this.props
    const { darkMode, device, images, orientation, settings } = this.state
    return {
      darkMode,
      device,
      devices,
      editable,
      images,
      orientation,
      preview,
      settings,
      type,
      version,
      versionable,
      website,
      onAction: this._handleAction,
      onDark: this._handleDark,
      onDevice: this._handleDevice,
      onImages: this._handleImages,
      onNavigate: this._handleNavigate,
      onOrientation: this._handleOrientation,
      onSettings: this._handleSettings
    }
  }

  _getLibrary(entity) {
    const { language, library, perspective, website } = this.props
    return {
      entity,
      language,
      library,
      perspective,
      website,
      onDone: this._handleUpdate.bind(this, true, false, entity.id)
    }
  }

  _getMain() {
    const { active, cards, darkMode, device, images, canvas_hover, orientation } = this.state
    const { editable, endpoint, version, type } = this.props
    return {
      active,
      cards,
      darkMode,
      device,
      devices,
      editable,
      version,
      hover: canvas_hover,
      images,
      key: endpoint,
      orientation,
      type,
      onAction: this._handleAction,
      onHover: _.debounce(this._handleHover.bind(this, 'outline'), 100, { trailing: true })
    }
  }

  _getNavigator() {
    const { device, outline_hover } = this.state
    const { type, version, versions, versionable, website } = this.props
    return {
      device,
      outline_hover,
      type,
      version,
      versions,
      versionable,
      website,
      onChangeTheme: this._handleUpdate.bind(this, false, false, null),
      onClone: this._handleClone,
      onEdit: this._handleEdit,
      onHover: _.debounce(this._handleHover.bind(this, 'canvas'), 100, { trailing: true }),
      onLibrary: this._handleLibrary,
      onMove: this._handleMove,
      onNew: this._handleNew,
      onRemove: this._handleRemove,
      onSaveTheme: this._handleUpdate.bind(this, true, false, null),
      onUnlink: this._handleUnlink
    }
  }

  _getNew(action, id, index) {
    const { language, library, perspective, version, type, website } = this.props
    return {
      language,
      library,
      perspective,
      theme: version.current.theme,
      type,
      website,
      onDone: (data) => this._handleAdd({ action, id, index, data })
    }
  }

  _getPanel() {
    const { root_endpoint, version, website } = this.props
    const { device } = this.state
    return {
      device,
      root_endpoint,
      website,
      theme: version.current.theme,
      onBack: this._handleSettings,
      onChange: this._handleUpdate.bind(this, false, false, null),
      onDone: this._handleUpdate.bind(this, true, false, null),
      onNavigate: this._handleNavigate,
      onSettings: this._handleSettings
    }
  }

  _getPreview() {
    const { endpoint } = this.props
    const parts = endpoint.split('/')
    return {
      id: parts[2],
      type: parts[1]
    }
  }

  _getTheme() {
    const { version, type, website } = this.props
    const { device } = this.state
    return {
      device,
      theme: version.current.theme,
      type,
      version,
      website,
      onChange: this._handleUpdate.bind(this, false, false, null),
      onDone: this._handleUpdate.bind(this, true, true, null)
    }
  }

  _handleAction(action, ...args) {
    const { alert } = this.context
    const { active } = this.state
    if(active) return alert.open({
      title: 't(ALERT)',
      message: 't(You must finish editing the entity before you can invoke this action)'
    })
    if(action === 'add') this._handleAdd(...args)
    if(action === 'clone') this._handleClone(...args)
    if(action === 'edit') this._handleEdit(...args)
    if(action === 'insert') this._handleInsert(...args)
    if(action === 'library') this._handleLibrary(...args)
    if(action === 'move') this._handleMove(...args)
    if(action === 'new') this._handleNew(...args)
    if(action === 'preview') this._handlePreview(...args)
    if(action === 'publish') this._handlePublish(...args)
    if(action === 'redo') this._handleRedo(...args)
    if(action === 'remove') this._handleRemove(...args)
    if(action === 'undo') this._handleUndo(...args)
  }

  _handleActive(active) {
    if(active === this.state.active) return
    this.setState({ active })
  }

  _handleAdd({ action, id, index, data }) {
    const config = this._getConfig()
    const added = action === 'insert' ? mjson.insert(config, id, index, data) : mjson.prepend(config, id, data)
    this.context.versions.update(added, true)
    if(data.entity !== 'block') return
    this._handleDelay(() => this._handleEdit({
      id: data.id,
      type: data.entity
    }), 250)
  }

  _handleClone({ id, type }) {
    const config = this._getConfig()
    const cloned = mjson.clone(config, id)
    this.context.versions.update(cloned, true)
  }

  _handleCommit() {
    this.context.versions.commit()
  }

  _handleDark() {
    const { darkMode } = this.state
    this.setState({
      darkMode: !darkMode
    })
  }

  _handleDelay(method, time) {
    if(this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(method, time)
  }

  _handleDevice(device) {
    this.setState({ 
      device,
      ...device === 'desktop' ? {  orientation: 'portrait' } : {}
    })
  }

  _handleEdit({ id, type }) {
    const { version } = this.props
    const { active } = this.state
    const Entity = this._getEntity('edit', type)
    this._handleActive(id)
    if(!active) return this._handlePush(Entity, this._getEdit.bind(this, id))
    this.context.versions.update(version.previous, false)
    this._handleReplace(Entity, this._getEdit.bind(this, id))  
  }

  _handleHover(location, { hover }) {
    if(!this.props.editable) return
    this.setState({
      [`${location}_hover`]: hover
    })
  }

  _handleImages() {
    const { images } = this.state
    this.setState({
      images: !images
    })
  }

  _handleInsert({ id, index, type }) {
    const Entity = this._getEntity('new', type)
    this.context.modal.open(<Entity { ...this._getNew('insert', id, index) } />)
  }

  _handleLibrary({ id }) {
    const { type } = this.props
    const config = this._getConfig()
    const path = mjson.getPath(config, id)
    const entity = _.get(config, path)
    const Component = entities[type].library
    this.context.modal.open(<Component { ...this._getLibrary(entity) } />)
  }

  _handleMove({ source, parent, index }) {
    const config = this._getConfig()
    const moved = mjson.move(config, source, parent, index)
    this.context.versions.update(moved, true)
  }

  _handleNavigate(endpoint) {
    this.props.onNavigate(endpoint)
  }

  _handleNew({ action, id, index, type }) {
    const Entity = this._getEntity('new', type)
    this.context.modal.open(<Entity { ...this._getNew(action, id, index) } />, {
      ...['email_article','email_introduction','email_module','page_module'].includes(type) ? {
        width: 1168,
        height: 740
      } : {}
    })
  }

  _handleOrientation() {
    const { orientation } = this.state
    this.setState({
      orientation: orientation === 'landscape' ? 'portrait' : 'landscape'
    })
  }

  _handlePop(index = -1) {
    this.setState({
      cards: this.state.cards.slice(0, index)
    })
  }

  _handlePreview(url) {
    const { host, modal } = this.context
    const { preview, type } = this.props
    if(type === 'page') host.window.open(preview)
    if(type === 'email') modal.open(<SendPreview { ...this._getPreview() } />)
  }

  _handlePublish() {
    this.context.versions.publish()
  }

  _handlePush(component, props) {
    this.setState({
      cards: [
        ...this.state.cards,
        { component, props }
      ]
    })
  }

  _handleRedo() {
    this.context.versions.redo()
  }

  _handleRemove({ id, type }) {
    this.context.confirm.open('t(Are you sure you want to remove this entity?)', () => {
      const config = this._getConfig()
      const removed = mjson.remove(config, id)
      this.context.versions.update(removed, true)
    })
  }

  _handleReplace(component, props) {
    this._handlePop()
    setTimeout(() => {
      this._handlePush(component, props)
    }, 300)
  }

  _handleSettings(settings) {
    const same = settings === this.state.settings
    if(!this.state.settings) return this.setState({ settings })
    this.setState({
      settings: null
    }, () => {
      if(!settings || same) return
      this._handleDelay(() => {
        this.setState({ settings })
      }, 250)
    })
  }

  _handleUpdate(commit, pop, id, obj) {
    const config = this._getConfig()
    const updated = mjson.update(config, id, obj)
    this.context.versions.update(updated, commit)
    if(!pop) return
    this._handleActive(null)
    this._handlePop()
  }

  _handleUndo() {
    this.context.versions.undo()
  }

  _handleUnlink(id) {
    this.context.confirm.open('t(Are you sure you want to unlink this entity?)', () => {
      const config = this._getConfig()
      const unlinked = mjson.unlink(config, id)
      this.context.versions.update(unlinked, true)
    })

  }

}

export default MJSONDesigner
