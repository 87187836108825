import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ email_campaign }) => {

  const design_url = email_campaign.design_url ? {
    label: email_campaign.design_url,
    className: 'link',
    link: email_campaign.design_url
  } : null

  const details = {
    header: <MJSONPreview entity={ email_campaign.variants[0] } table="truevail_email_campaign_variants" />,
    sections: [
      {
        title: 't(Email Campaign Details)',
        items: [
          { label: 't(Title)', content: email_campaign.title },
          { label: 't(Design URL)', content: design_url ? <Button { ...design_url } /> : null },
          { label: 't(Tags)', content: email_campaign.tags.length > 0 ? <TagsToken tags={ email_campaign.tags } /> : null }
        ]
      }
    ]
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  email_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email_campaign={ props.email_campaign } />,
  tasks: {
    items: [
      {
        label: 't(Download Performance Report)',
        url: `/api/admin/truevail/agency/email_performances/${props.email_campaign.id}/download?token=${context.admin.token}`
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
