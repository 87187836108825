import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import Publish from '../publish'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ email_campaign }, { admin, provider }) => {

  const design_url = email_campaign.design_url ? {
    label: email_campaign.design_url,
    className: 'link',
    link: email_campaign.design_url
  } : null

  const contentlibrary_url = {
    label: `${provider.admin_host}/${admin.team.subdomain}/contentlibrary/email_campaigns/${email_campaign.code}`,
    className: 'link',
    link: `${provider.admin_host}/${admin.team.subdomain}/contentlibrary/email_campaigns/${email_campaign.code}`
  }

  const details = {
    ...email_campaign.deleted_at !== null ? {
      alert: { color: 'red', message: 't(This email campaign was deleted)' }
    } : email_campaign.status === 'translating' ? {
      alert: { color: 'teal', message: 't(This email campaign is being translated)' }
    } : {},
    audits: `truevail_email_campaigns/${email_campaign.id}`,
    comments: `truevail_email_campaigns/${email_campaign.id}`,
    header: <MJSONPreview entity={ email_campaign.variants[0] } table="truevail_email_campaign_variants" />,
    sections: [
      {
        title: 't(Email Campaign Details)',
        items: [
          { label: 't(Title)', content: email_campaign.title },
          { label: 't(Design URL)', content: design_url ? <Button { ...design_url } /> : null },
          { label: 't(Library URL)', content: email_campaign.status === 'published' ? <Button { ...contentlibrary_url } /> : null },
          { label: 't(Library Folders)', content: email_campaign.folders.length > 0 ? email_campaign.folders.map(folder => folder.title).join(', ') : null },
          { label: 't(Tags)', content: email_campaign.tags.length > 0 ? <TagsToken tags={ email_campaign.tags } /> : null }
        ]
      }
    ]
  }

  if(email_campaign.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This email campaign was deleted)' }
  } else if(email_campaign.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This email campaign is not available in the library)' }
  } else if(email_campaign.status === 'translating') {
    details.alert = { color: 'teal', message: 't(This email campaign is being translated)' }
  } else if(email_campaign.status === 'published') {
    details.alert = { color: 'green', message: 't(This email campaign is available in the library)' }
  }

  return <Details { ...details } />

}

Overview.contextTypes = {
  admin: PropTypes.object,
  provider: PropTypes.object
}

Overview.propTypes = {
  email_campaign: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview email_campaign={ props.email_campaign } />,
  tasks: {
    items: [
      {
        label: 't(Edit Campaign)',
        modal: <Edit email_campaign={ props.email_campaign } />
      },
      {
        label: 't(Clone Campaign)',
        modal: <Clone email_campaign={ props.email_campaign } />
      },
      {
        label: 't(Add to Library)',
        show: props.email_campaign.status === 'draft',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${props.email_campaign.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully added this email campaign to the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to add this email campaign to the library)')
        }
      },
      {
        label: 't(Remove from Library)',
        show: props.email_campaign.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${props.email_campaign.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully removed this email campaign from the library)'),
          onFailure: () => context.flash.set('error', 't(Unable to remove this email campaign from the library)')
        }
      },
      {
        label: 't(Publish Advisor Campaigns)',
        show: props.email_campaign.is_spam === false,
        modal: <Publish email_campaign={ props.email_campaign } />
      },
      {
        label: 't(Delete Campaign)',
        confirm: 't(Are you sure you want to delete this campaign? You will also delete all of the associated workflows and performance data)',
        request: {
          endpoint: `/api/admin/truevail/agency/email_campaigns/${props.email_campaign.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', 't(Successfully deleted campaign)')
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', 't(Unable to delete campaign)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
