import MJSONPreview from '@admin/components/mjson_preview'
import TagsToken from '@apps/crm/admin/tokens/tags'
import Details from '@admin/components/details'
import Panel from '@admin/components/panel'
import Translate from '../translate'
import PropTypes from 'prop-types'
import types from '../types'
import Clone from '../clone'
import Edit from '../edit'
import React from 'react'

const Overview = ({ template }) => {

  const details = {
    audits: `truevail_templates/${template.id}`,
    comments: `truevail_templates/${template.id}`,
    header:  <MJSONPreview entity={ template.variants[0] } table="truevail_template_variants" />,
    sections: [
      {
        title: 't(Template Details)',
        items: [
          { label: 't(Title)', content: template.title },
          { label: 't(Type)', content: template.type },
          { label: 't(Tags)', content: template.tags.length > 0 ? <TagsToken tags={ template.tags } /> : null }
        ]
      }
    ]
  }

  if(template.deleted_at !== null) {
    details.alert = { color: 'red', message: 't(This template was deleted)' }
  } else if(template.status === 'draft') {
    details.alert = { color: 'grey', message: 't(This template is in draft mode)' }
  } else if(template.status === 'translating') {
    details.alert = { color: 'teal', message: 't(This templateis being translated)' }
  } else if(template.status === 'published') {
    details.alert = { color: 'green', message: 't(This template is published)' }
  }

  return <Details { ...details } />

}

Overview.propTypes = {
  template: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview template={ props.template } />,
  tasks: {
    items: [
      {
        label: types[props.template.type].edit_label,
        modal: <Edit template={ props.template } />
      },
      {
        label: types[props.template.type].clone_label,
        modal: <Clone template={ props.template } />
      },
      {
        label: types[props.template.type].translate_label,
        modal: <Translate template={ props.template } />
      },
      {
        label: types[props.template.type].publish_label,
        show: props.template.status === 'draft',
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${props.template.id}/publish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', types[props.template.type].publish_success),
          onFailure: () => context.flash.set('error', types[props.template.type].publish_error)
        }
      },
      {
        label: types[props.template.type].unpublish_label,
        show: props.template.status === 'published',
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${props.template.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', types[props.template.type].unpublish_success),
          onFailure: () => context.flash.set('error', types[props.template.type].unpublish_error)
        }
      },
      {
        label: types[props.template.type].delete_label,
        show: props.template.status !== 'published',
        confirm: types[props.template.type].delete_confirm,
        request: {
          endpoint: `/api/admin/truevail/agency/templates/${props.template.id}`,
          method: 'DELETE',
          onSuccess: () => {
            context.flash.set('success', types[props.template.type].delete_success)
            context.router.goBack()
          },
          onFailure: () => context.flash.set('error', types[props.template.type].delete_error)
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
