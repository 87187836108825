import ProfileToken from '@admin/tokens/profile'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Post Collection Failures)',
  collection: {
    endpoint: '/api/admin/truevail/admin/postability/post_collection_failures',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'profile.username', sort: 'profile', primary: true, format: (variant) => (
        <ProfileToken profile={ variant.profile } />
      ) },
      { label: 't(Error)', key: 'collection_error' },
      { label: 't(Collected)', key: 'last_collected_at', format: 'date' }
    ],
    defaultSort: { key: 'last_collected_at', order: 'desc' },
    empty: {
      icon: 'times',
      title: 't(No Collection Failures)',
      text: 't(There are no collection failures)'
    },
    entity: 'records',
    onClick: (record) => {
      const { provider, host } = context
      const { subdomain } = record.profile.team
      host.window.open(`${provider.admin_host}/${subdomain}/campaigns/social/${record.social_campaign_id}`)
    }
  }
})

export default Panel(null, mapPropsToPanel)
