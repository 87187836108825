import ProfileToken from '@admin/tokens/profile'
import SourceToken from '@admin/tokens/source'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Profiles)',
  collection: {
    endpoint: '/api/admin/truevail/admin/profiles',
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Profile)', key: 'username', sort: 'name', primary: true, format: (profile) => (
        <ProfileToken profile={ profile } />
      ) },
      { label: 't(Status)', key: 'status', sort: 'status', primary: true, collapsing: true, format: StatusToken }
    ],
    criteria: {
      fields: [
        {
          label: 't(Fields)',
          fields: [
            { name: 't(Service)', key: 'service', type: 'select', multiple: true, options: [
              { name: 'Instagram', source_name: 'instagram_business' },
              { name: 'Facebook', source_name: 'facebook' }
            ], format: (source) => (
              <SourceToken source={ source } />
            ), valueKey: 'source_name', textKey: 'name' },
            { name: 't(Status)', key: 'status', type: 'select', multiple: true, options: [
              { value: 'connected', text: 'connected' },
              { value: 'disconnected', text: 'disconnected' },
              { value: 'outdated_permissions', text: 'outdated permissions' },
              { value: 'revoked', text: 'revoked' }
            ], format: StatusToken }
          ]
        }
      ]
    },
    empty: {
      title: 't(No Social Profiles)',
      ...props.status === 'disconnected' ? {
        text: 't(There are no disconnected profiles)'
      } : props.status === 'expired_password' ? {
        text: 't(There are no expired profiles)'
      } : {
        text: 't(There are no profiles)'
      },
      icon: 'id-card-o'
    },
    entity: 'profile',
    ...props.status ? {
      defaultQuery: {
        status: props.status
      }
    } : {},
    defaultSort: { key: 'created_at', order: 'desc' }
  }
})

export default Panel(null, mapPropsToPanel)
