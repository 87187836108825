import MJSONDesigner from '@admin/components/mjson_designer'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

class Design extends React.Component {

  static propTypes = {
    notice: PropTypes.object
  }

  render() {
    return <MJSONDesigner { ...this._getMJSONDesigner() } />
  }

  _getMJSONDesigner() {
    const { notice } = this.props
    return {
      editable: true,
      endpoint: `/maha_provider_notices/${notice.id}`,
      root_route: `/truevail/admin/notices/${notice.id}/design`,
      type: 'email',
      versionable: true
    }
  }

}

const mapPropsToPanel = (props, context) => ({
  title: 't(Design)',
  component: <Design notice={ props.notice } />
})

export default Panel(null, mapPropsToPanel)
