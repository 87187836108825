import Page from '@admin/components/page'
import Publishings from './campaigns'
import Overview from './overview'
import React from 'react'

const mapPropsToPage = (props, context, resources, page) => ({
  title: resources.social_campaign.title,
  manager: {
    path: `/admin/truevail/agency/social_performance/${resources.social_campaign.id}`,
    items: [
      {
        label: 't(Overview)',
        panel: <Overview social_campaign={ resources.social_campaign } />
      },
      {
        label: 't(Publishings)',
        path: '/publishings',
        panel: <Publishings social_campaign={ resources.social_campaign }  />
      }
    ]
  }
})

const mapResourcesToPage = (props, context) => ({
  social_campaign: `/api/admin/truevail/agency/social_performances/${props.params.id}`
})

export default Page(mapResourcesToPage, mapPropsToPage)
