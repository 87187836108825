import PublishingToken from '@apps/truevail/admin/tokens/publishing'
import StatusToken from '@admin/tokens/status'
import Panel from '@admin/components/panel'
import Schedule from '../schedule'
import Publish from '../publish'
import Preview from '../preview'
import React from 'react'

const mapPropsToPanel = (props, context) => ({
  title: 't(Emails)',
  collection: {
    endpoint: `/api/admin/truevail/agency/email_campaigns/${props.email_campaign.id}/publishings`,
    table: [
      { label: 't(ID)', key: 'id', collapsing: true, visible: false },
      { label: 't(Advisor)', key: 'advisor.title', sort: 'advisor', format: (publishing) => (
        <PublishingToken publishing={ publishing } />
      ) },
      { label: 't(Published)', key: 'created_at', collapsing: true, format: 'datetime' },
      { label: 't(Preview)', key: 'preview_status', collapsing: true, align: 'center', format: (publishing) => (
        <StatusToken status={ publishing.preview_status } />
      ) }
    ],
    defaultSort: { key: 'advisor', order: 'asc' },
    empty: {
      icon: 'envelope',
      title: 't(No Publishings)',
      text: 't(There are no publishings for this email campaign)',
      buttons: [
        {
          label: 't(Publish Email Campaigns)',
          modal: <Publish email_campaign={ props.email_campaign } />
        }
      ]
    },
    entity: 'publishing',
    recordTasks: (record) => [
      {
        label: 't(Send Preview)',
        modal: <Preview publishing={ record } />
      },
      {
        label: 't(Republish Email Campaign)',
        show: _.includes(['draft','scheduled','unpublished'], record.advisor_email_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/republish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully republished email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to republish email campaign)')
        }
      },
      {
        label: 't(Schedule Email Campaign)',
        show: _.includes(['draft','unpublished'], record.advisor_email_campaign.status),
        modal: <Schedule email_campaign_id={ props.email_campaign.id } publishing={ record } />
      },
      {
        label: 't(Reschedule Email Campaign)',
        show: record.advisor_email_campaign.status === 'scheduled',
        modal: <Schedule email_campaign_id={ props.email_campaign.id } publishing={ record } />
      },
      {
        label: 't(Unpublish Email Campaign)',
        show: _.includes(['draft','scheduled'], record.advisor_email_campaign.status),
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unpublish`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unpublished email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unpublish email campaign)')
        }
      },
      {
        label: 't(Unschedule Email Campaign)',
        show: record.advisor_email_campaign.status === 'scheduled',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}/unschedule`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Successfully unscheduled email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to unschedule email campaign)')
        }
      },
      {
        label: 't(Delete Email Campaign)',
        confirm: 't(Are you sure you want to delete this email campaign?)',
        request: {
          endpoint: `/api/admin/truevail/agency/publishings/${record.id}`,
          method: 'DELETE',
          onSuccess: () => context.flash.set('success', 't(Successfully deleted email campaign)'),
          onFailure: () => context.flash.set('error', 't(Unable to delete email campaign)')
        }
      }
    ],
    onClick: (record) => {
      const { provider, host } = context
      host.window.open(`${provider.admin_host}/mjson/dynamic/campaigns_email_campaigns/${record.advisor_email_campaign.code}`)
    }
  },
  task: {
    icon: 'plus',
    tooltip: 't(Publish Email Campaigns)',
    modal: <Publish email_campaign={ props.email_campaign } />
  }
})

export default Panel(null, mapPropsToPanel)
