const triggers = [
  {
    icon: 'check-square',
    manual: true,
    code: 'demo_scheduled',
    title: 't(Demo Scheduled)',
    text: 't(Contact scheduled a demo)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'demo_rescheduled',
    title: 't(Demo Recheduled)',
    text: 't(Contact rescheduled a demo)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'demo_canceled',
    title: 't(Demo Canceled)',
    text: 't(Contact canceled a demo)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'demo_completed',
    title: 't(Demo Completed)',
    text: 't(Contact completed a demo)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'demo_converted',
    title: 't(Demo Converted)',
    text: 't(Contact signed up after a demo)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'participant_registered',
    title: 't(Participant Registered)',
    text: 't(Contact registered for webinar)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'participant_attended',
    title: 't(Participant Attended)',
    text: 't(Contact attended webinar)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'participant_noshowed',
    title: 't(Participant Did Not Attend)',
    text: 't(Contact did not attend webinar)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  },
  {
    icon: 'check-square',
    manual: true,
    code: 'participant_converted',
    title: 't(Participant Converted)',
    text: 't(Contact signed up after webinar)',
    access: { rights: { $allOf: ['truevail:access_admin'] } }
  }
]

export default triggers
