import MJSONScreenshot from '@admin/components/mjson_screenshot'
import PropTypes from 'prop-types'
import React from 'react'

const getScreenshot = (entity) => ({
  engine: 'mjson',
  entity: `truevail_template_variants/${entity.code}`,
  width: 100,
  height: 75,
  screenshoted_at: entity.screenshoted_at
})

const TemplateToken = ({ template, show_provider, show_tags }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        <MJSONScreenshot { ...getScreenshot(template.variants[0]) } />
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { template.title }
      </div>
      { show_provider &&
        <>
          { template.agency ?
            <div className="token-stats">
              <span>Agency: { template.agency.title }</span>
            </div> :
            template.team ?
              <div className="token-stats">
                <span>Team: { template.team.title }</span>
              </div> :
              <div className="token-stats">
                <span>Provider: Truevail</span>
              </div>    
          }
        </>
      }
    </div>
  </div>
)

TemplateToken.propTypes = {
  template: PropTypes.object,
  show_provider: PropTypes.bool,
  show_tags: PropTypes.bool
}

export default TemplateToken
