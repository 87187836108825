import Details from '@admin/components/details'
import Button from '@admin/components/button'
import Panel from '@admin/components/panel'
import PropTypes from 'prop-types'
import React from 'react'

const Overview = ({ webinar }) => {

  const zoom_join = {
    label: webinar.zoom_join_url,
    className: 'link',
    link: webinar.zoom_join_url 
  }

  const zoom_register = {
    label: webinar.zoom_register_url,
    className: 'link',
    link: webinar.zoom_register_url 
  }

  const details = {
    audits: `truevail_webinars/${webinar.id}`,
    comments: `truevail_webinars/${webinar.id}`,
    sections: [
      {
        title: 't(Webinar)',
        items: [
          { label: 't(Title)', content: webinar.title },
          { label: 't(Zoom Register Url)', content: <Button { ...zoom_register } /> },
          { label: 't(Zoom Join Url)', content: <Button { ...zoom_join } /> },
          { label: 't(Start Time)', content: webinar.started_at, format: 'datetime' },
          { label: 't(End Time)', content: webinar.ended_at, format: 'datetime' }
        ]
      }
    ]
  }
  
  return <Details { ...details } />

}

Overview.propTypes = {
  webinar: PropTypes.object
}

const mapPropsToPanel = (props, context) => ({
  title: 't(Overview)',
  panel: <Overview webinar={ props.webinar } />,
  tasks: {
    items: [
      {
        label: 't(Mark as Completed)',
        show: ['active'].includes(props.webinar.status),
        request: {
          endpoint: `/api/admin/truevail/admin/webinars/${props.webinar.id}/complete`,
          method: 'PATCH',
          onSuccess: () => context.flash.set('success', 't(Sucessfully completed this webinar)'),
          onFailure: () => context.flash.set('error', 't(Unable to complete this webinar)')
        }
      }
    ]
  }
})

export default Panel(null, mapPropsToPanel)
