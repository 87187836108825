export const getConstrainedDimensions = (width, height, boundingWidth, boundingHeight) => {
  const constrainedWidth = width <= boundingWidth && height <= boundingHeight ? width : Math.min(boundingWidth, width, (boundingHeight * width) / height)
  const constrainedHeight = (constrainedWidth * height) / width
  return { 
    width: Math.floor(constrainedWidth), 
    height: Math.floor(constrainedHeight)
  }
}

export const getConstrainedWidth = (width, height, boundingWidth, boundingHeight) => {
  const constrained = getConstrainedDimensions(width, height, boundingWidth, boundingHeight)
  return constrained.width
}
