import Container from '@admin/components/container'
import Stack from '@admin/components/stack'
import PropTypes from 'prop-types'
import Complete from './complete'
import Validate from './validate'
import Process from './process'
import Sources from './sources'
import Review from './review'
import Intro from './intro'
import React from 'react'

class Import extends React.PureComponent {

  static contextTypes = {
    admin: PropTypes.object,
    modal: PropTypes.object
  }

  static propTypes = {
    defaults: PropTypes.object,
    complete: PropTypes.any,
    configure: PropTypes.any,
    entity: PropTypes.string,
    imports: PropTypes.array,
    object_type: PropTypes.string,
    sources: PropTypes.array,
    summary: PropTypes.func,
    onBack: PropTypes.func,
    onCancel: PropTypes.func
  }

  static defaultProps = {
    defaults: {},
    complete: Complete,
    entity: 'item',
    sources: ['file']
  }

  stackRef = React.createRef()


  _handleComplete = this._handleComplete.bind(this)
  _handleConfigure = this._handleConfigure.bind(this)
  _handleDone = this._handleDone.bind(this)
  _handleIntro = this._handleIntro.bind(this)
  _handleNew = this._handleNew.bind(this)
  _handlePop = this._handlePop.bind(this)
  _handleProcess = this._handleProcess.bind(this)
  _handlePush = this._handlePush.bind(this)
  _handleResume = this._handleResume.bind(this)
  _handleSources = this._handleSources.bind(this)
  _handleReview = this._handleReview.bind(this)
  _handleValidate = this._handleValidate.bind(this)

  render() {
    return (
      <div className="import">
        <Stack { ...this._getStack() } />
      </div>
    )
  }

  componentDidMount() {
    this._handleNew()
  }

  _getStack() {
    return {
      display_name: 'import',
      ref: this.stackRef
    }
  }

  _handleComplete(_import) {
    const { complete } = this.props
    this._handlePush(complete, {
      _import,
      onDone: this._handleDone
    })
  }

  _handleConfigure(_import) {
    const { configure } = this.props
    if(!configure) this._handleValidate(_import)
    this._handlePush(configure, {
      _import,
      onBack: this._handlePop,
      onDone: this._handleValidate
    })
  }

  _handleDone() {
    this.context.modal.close()
  }

  _handleIntro() {
    const { object_type, onBack, onCancel } = this.props
    this._handlePush(Intro, {
      object_type,
      onBack,
      onCancel,
      onNew: this._handleSources,
      onResume: this._handleResume
    })
  }

  _handleNew() {
    const { imports } = this.props
    if(imports.length > 0) return this._handleIntro()
    this._handleSources()
  }

  _handlePop(index = -1) {
    this.stackRef.current.pop(index)
  }

  _handleProcess(_import) {
    const { entity } = this.props
    const props = {
      _import,
      entity,
      onDone: this._handleComplete
    }
    this._handlePush(Process, props)
  }

  _handlePush(component, props) {
    this.stackRef.current.push({ component, props })
  }

  _handleResume(_import) {
    const { status } = _import
    if(status === 'configuring') this._handleConfigure(_import) 
    if(status === 'validating') this._handleValidate(_import) 
    if(status === 'reviewing') this._handleReview(_import) 
    if(status === 'processing') this._handleProcess(_import) 
  }

  _handleReview(_import) {
    const { entity, summary } = this.props
    this._handlePush(Review, {
      _import,
      entity,
      summary,
      onBack: this._handlePop,
      onDone: this._handleProcess,
      onPop: this._handlePop,
      onPush: this._handlePush
    })
  }

  _handleSources() {
    const { defaults, configure, object_type, sources, onBack, onCancel } = this.props
    const { imports } = this.props
    this._handlePush(Sources, {
      _import: {
        ...defaults || {}
      },
      object_type,
      sources,
      ...imports.length > 0 ? {
        onBack: this._handlePop
      } : {
        onBack,
        onCancel  
      },
      onPop: this._handlePop,
      onDone: configure ? this._handleConfigure : this._handleValidate,
      onPush: this._handlePush
    })
  }

  _handleValidate(_import) {
    this._handlePush(Validate, {
      _import,
      onBack: this._handlePop,
      onDone: this._handleReview
    })
  }

}

const mapResources = (props, context) => ({
  imports: {
    endpoint: `/api/admin/${props.object_type}/imports`,
    filter: {
      status: {
        $neq: 'completed'
      }
    }
  }
})

export default Container(mapResources)(Import)
