import TemplateToken from '@apps/truevail/admin/tokens/template'
import EntityChooser from '@admin/components/entity_chooser'
import { getCode } from '@core/utils/codes'
import PropTypes from 'prop-types'
import React from 'react'

class NewModule extends React.Component {

  static contextTypes = {
    flash: PropTypes.object,
    modal: PropTypes.object,
    network: PropTypes.object
  }

  static propTypes = {
    language: PropTypes.string,
    library: PropTypes.object,
    perspective: PropTypes.string,
    theme: PropTypes.object,
    type: PropTypes.string,
    onDone: PropTypes.func
  }

  _handleCancel = this._handleCancel.bind(this)
  _handleFetch = this._handleFetch.bind(this)
  _handleSubmit = this._handleSubmit.bind(this)

  render() {
    return <EntityChooser { ...this._getChooser() } />
  }

  _getChooser() {
    const { language, library, perspective, type } = this.props
    return {
      endpoint: `/api/admin/truevail/library${library}/templates/${type}`,
      defaultQuery: {
        language,
        perspective
      },
      entity: type.replace('_', ' '),
      format: (template) => (
        <TemplateToken template={ template } show_provider={ true } />
      ),
      multiple: false,
      screenshot: (entity) => `truevail_template_variants/${entity.variants[0].code}`,
      onCancel: this._handleCancel,
      onDone: this._handleFetch
    }
  }

  _getSection(entity) {
    if(entity.entity === 'section') return entity
    return this._getSection(entity.content.children[0])
  } 

  _getUnique(config) {
    return {
      ...config,
      id: getCode(10),
      content: {
        ...config.content,
        ...config.content.children ? {
          children: config.content.children.map(child => {
            return this._getUnique(child)
          })
        } : {}
      }
    } 
  }

  _handleCancel() {
    this.context.modal.close()
  }

  _handleFetch(template) {
    const { language, perspective } = this.props
    this.context.network.request({
      endpoint: `/api/admin/truevail/library/templates/${template.id}`,
      method: 'GET',
      query: {
        language,
        perspective
      },
      onFailure: () => this.context.flash.set('error', 't(Unable to load templates)'),
      onSuccess: ({ data }) => {
        this._handleSubmit(data)
      }
    })
  }

  _handleSubmit(template) {
    this.props.onDone({
      ...this._getUnique({
        ...template.config.entity,
        entity: 'section'
      })
    })
    this.context.modal.close()
  }

}

export default NewModule
