import PropTypes from 'prop-types'
import React from 'react'

const AestheticProfileToken = ({ aesthetic_profile }, { provider }) => (
  <div className="campaign-token compact">
    <div className="campaign-token-preview">
      <div className="campaign-token-preview-inner">
        { aesthetic_profile.thumbnail &&
          <img src={`${provider.cdn_host}/imagecache/w=100&fit=cover${aesthetic_profile.thumbnail.path}`} />
        }
      </div>
    </div>
    <div className="campaign-token-label">
      <div className="token-link">
        { aesthetic_profile.title }
      </div>
    </div>
  </div>
)

AestheticProfileToken.contextTypes = {
  provider: PropTypes.object
}

AestheticProfileToken.propTypes = {
  aesthetic_profile: PropTypes.object
}

export default AestheticProfileToken
